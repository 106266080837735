import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, Switch, Row, Col, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import './navbar.css';

const Navbar = ({
  connectWallet,
  getCurrentWalletConnected,
  childToParent,
  admin,
  maxSupply,
  mintedItems,
  pageMode,
  setPageMode,
  manualAddressCheck,
  forceManualAddressCheck,
  baked,
}) => {
  const [walletAddress, setWallet] = useState('');
  const [status, setStatus] = useState('');

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  useEffect(() => {
    fetchWallet();
    async function fetchWallet() {
      const { address, status } = await getCurrentWalletConnected();
      setWallet(address);
      setStatus(status);
    }
    childToParent(walletAddress);
    forceManualAddressCheck(walletAddress);
    addWalletListener();
  }, [walletAddress]);

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus('👆🏽 Write a message in the text-field above.');
        } else {
          setWallet('');
          setStatus('🦊 Connect to Metamask.');
        }
      });
    } else {
      setStatus(
        <p>
          {' '}
          🦊{' '}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onChange = () => {
    if (pageMode == 'white') setPageMode('black');
    if (pageMode == 'black') setPageMode('white');
  };

  return (
    <>
      {admin.toLowerCase() == walletAddress.toLowerCase() ? (
        <div className={pageMode == 'white' ? 'navbar' : 'navbar-black'}>
          <Link className="menu-btn" to="/" onClick={manualAddressCheck}>
            Kitchen
          </Link>
          <Link className="menu-btn" to="/mypizzas">
            My Recipes
          </Link>
          <Link className="menu-btn" to="/franchise">
            Franchise
          </Link>
          <Link className="menu-btn" to="/market">
            Market
          </Link>
          <Link className="menu-btn" to="/mymachines">
            My Machines
          </Link>
          <Link className="menu-btn" to="/about">
            About
          </Link>
          <Link className="menu-btn" to="/admin-pizzapp">
            Admin
          </Link>
          <h4 className="counter">
            {mintedItems == 'loading' ? (
              <Spin tip="Loading Baked Pizzas" indicator={antIcon} />
            ) : (
              `Baked Pizzas: ${baked}/${new Intl.NumberFormat('en-IN', {
                maximumSignificantDigits: 3,
              }).format(maxSupply)}`
            )}
          </h4>
          <Button
            style={{ backgroundColor: 'rgb(68, 182, 117)', color: 'white' }}
            onClick={connectWalletPressed}
          >
            {status}
          </Button>
          <Row gutter={2} style={{ paddingTop: 10, paddingLeft: 10 }}>
            <Col>
              <p style={{ paddingRight: 2 }}>🌞</p>
            </Col>
            <Col>
              <Switch onChange={onChange} />
            </Col>
            <Col>
              <p style={{ paddingLeft: 2 }}>🌚</p>
            </Col>
          </Row>
        </div>
      ) : (
        <div className={pageMode == 'white' ? 'navbar' : 'navbar-black'}>
          <Link className="menu-btn" to="/" onClick={manualAddressCheck}>
            Kitchen
          </Link>
          <Link className="menu-btn" to="/mypizzas">
            My Recipes
          </Link>
          <Link className="menu-btn" to="/franchise">
            Franchise
          </Link>
          <Link className="menu-btn" to="/market">
            Market
          </Link>
          <Link className="menu-btn" to="/mymachines">
            My Machines
          </Link>
          <Link className="menu-btn" to="/about">
            About
          </Link>
          <h4 className="counter">
            {mintedItems == 'loading' ? (
              <Spin tip="Loading Baked Pizzas" indicator={antIcon} />
            ) : (
              `Baked Pizzas: ${baked}/${new Intl.NumberFormat('en-IN', {
                maximumSignificantDigits: 3,
              }).format(maxSupply)}`
            )}
          </h4>
          <Button
            style={{ backgroundColor: 'rgb(68, 182, 117)', color: 'white' }}
            onClick={connectWalletPressed}
          >
            {status}
          </Button>
          <Row gutter={2} style={{ paddingTop: 10, paddingLeft: 10 }}>
            <Col>
              <p style={{ paddingRight: 2 }}>🌞</p>
            </Col>
            <Col>
              <Switch onChange={onChange} />
            </Col>
            <Col>
              <p style={{ paddingLeft: 2 }}>🌚</p>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default Navbar;
