import { useState, useEffect } from 'react';
import './mynfts.css';
import { ethers } from 'ethers';
import { winnersnftaddress } from '../config';
import WinnersNFT from '../_artifacts/WinnersNFT.sol/WinnersNFT.json';
import {
  Card,
  Row,
  Col,
  Button,
  Divider,
  Modal,
  Steps,
  Statistic,
  Spin,
  Badge,
} from 'antd';

import F_CheesyRockets from '../imgs/F_CheesyRockets.png';
import F_Ludos from '../imgs/F_Ludos.png';
import F_MeltingMoon from '../imgs/F_MeltingMoon.png';
import F_PizzApp from '../imgs/F_PizzApp.png';
import F_PizzaXpress from '../imgs/F_PizzaXpress.png';
import F_SliceOfHeaven from '../imgs/F_SliceOfHeaven.png';

import franchise_logo_01 from '../imgs/franchise_logo_01.png';
import franchise_logo_02 from '../imgs/franchise_logo_02.png';
import franchise_logo_03 from '../imgs/franchise_logo_03.png';
import franchise_logo_04 from '../imgs/franchise_logo_04.png';
import franchise_logo_05 from '../imgs/franchise_logo_05.png';
import franchise_logo_06 from '../imgs/franchise_logo_06.png';
import background02 from '../imgs/background02.svg';
import { LoadingOutlined } from '@ant-design/icons';

const MyNFTs = ({
  pageMode,
  currentWallet,
  providerSwitch,
  ingredientsImages,
  ingredientsIcons,
  ingredientsCodesReversed,
  franchises,
  appIcons,
}) => {
  const [mintData, setMintData] = useState('');
  const [openAboutFranchises, setOpenAboutFranchises] = useState(false);
  const [openLuckModal, setOpenLuckModal] = useState(false);
  const [maxSupply, setMaxSupply] = useState('');
  const [minted, setMinted] = useState('');

  useEffect(() => {
    console.log('first');
    setMintData('loading');
    setMaxSupply('loading');
    setMinted('loading');
    winnersData();
    loadMintData();
  }, []);

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const handleOkAboutModal = () => {
    setOpenAboutFranchises(false);
  };

  const showAboutModal = () => {
    setOpenAboutFranchises(true);
  };

  const showLuckModal = () => {
    setOpenLuckModal(true);
  };
  const handleOkLuckModal = () => {
    setOpenLuckModal(false);
  };

  async function loadMintData() {
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const contract = new ethers.Contract(
      winnersnftaddress,
      WinnersNFT.abi,
      provider
    );

    const _minted = await contract.getCurrentMinted();
    setMinted(_minted);
    const _maxSupply = await contract.getMaxSupply();
    setMaxSupply(_maxSupply);
  }

  const franchisesData = [
    {
      title: franchises.at(0).name,
      img: franchise_logo_01,
      id: 0,
      franchiseImg: F_Ludos,
    },
    {
      title: franchises.at(1).name,
      img: franchise_logo_02,
      id: 1,
      franchiseImg: F_CheesyRockets,
    },
    {
      title: franchises.at(2).name,
      img: franchise_logo_03,
      id: 2,
      franchiseImg: F_PizzaXpress,
    },
    {
      title: franchises.at(3).name,
      img: franchise_logo_04,
      id: 3,
      franchiseImg: F_MeltingMoon,
    },
    {
      title: franchises.at(4).name,
      img: franchise_logo_05,
      id: 4,
      franchiseImg: F_SliceOfHeaven,
    },
    {
      title: franchises.at(5).name,
      img: franchise_logo_06,
      id: 5,
      franchiseImg: F_PizzApp,
    },
  ];

  async function winnersData() {
    console.log('first');
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const contract = new ethers.Contract(
      winnersnftaddress,
      WinnersNFT.abi,
      provider
    );
    try {
      const mintedData = await contract.getMintedItems(currentWallet); //new method
      const mintedItems = await Promise.all(
        mintedData.map(async (i) => {
          let currentOwner = await contract.ownerOf(i); //new method i.itemId
          let metadata = await contract.getMetadata(i); //new method i.itemId
          //NFTonChain changes
          let item = {
            tokenId: i.toNumber(), //new method i.itemId.toNumber()
            minterAddress: currentWallet, //new method i.minterAddress
            owner: currentOwner,
            metadata: metadata,
          };
          return item;
        })
      );
      console.log('W mintedItems', mintedItems);
      setMintData(mintedItems);
    } catch (error) {
      console.log('Error on Checcking for Keys', error);
    }
  }
  return (
    <div
      style={{
        backgroundImage: `url(${background02})`,
        backgroundSize: '30%',
        backgroundColor: pageMode,
      }}
    >
      <Modal
        title="About Franchises"
        width="1024px"
        visible={openAboutFranchises}
        style={{ fontFamily: 'Sniglet' }}
        closable={false}
        footer={[
          <Button type="primary" onClick={handleOkAboutModal}>
            Close
          </Button>,
        ]}
      >
        <Row>
          <Card
            title="Franchises & Status"
            bordered={false}
            headStyle={{
              backgroundColor: '#ff0278',
              color: 'white',
              borderRadius: 10,
            }}
          >
            {' '}
            <h3 style={{ color: '#ff0278' }}>
              There are 5 posible Status you can get when Baking a Pizza:
            </h3>
            <Row>
              <Col span={12}>
                <Row>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['overcooked']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Overcooked
                    </p>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['undercooked']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Undercooked
                    </p>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['good']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Good
                    </p>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['great']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Great
                    </p>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['perfect']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Perfect
                    </p>
                  </div>
                </Row>
              </Col>
              <Col span={12}>
                {' '}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={appIcons['perfect']}
                    style={{ width: 64, paddingBottom: 20 }}
                  />
                  <p style={{ paddingTop: 20 }}>
                    If you Bake a Perfect Pizza, you can use it to Unlock one of
                    the Six Franchsises.
                  </p>
                </div>
              </Col>
            </Row>
            <h3 style={{ color: '#ff0278' }}>The Franchsies:</h3>
            <div style={{ display: 'flex' }}>
              {' '}
              {franchisesData.map((data) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 12,

                    alignItems: 'center',
                  }}
                >
                  <img src={data.img} style={{ width: 128 }} />

                  <p>{`${data.title}`}</p>
                </div>
              ))}
            </div>
          </Card>
        </Row>
        <Row>
          <Card
            title="The Machine & Luck"
            bordered={false}
            headStyle={{
              backgroundColor: '#ff0278',
              color: 'white',
              borderRadius: 10,
            }}
          >
            {' '}
            <p>
              You can Send up to 5 Pizzas to each Franchise, each Pizza gives
              you a permanent +1.2% Boost on your 🤞Owner's Luck.
            </p>
            <Badge color="green" count={`🍕 2`}>
              {' '}
              <img src={franchisesData.at(4).img} style={{ width: 128 }} />
            </Badge>
            <p>
              Once you sent 5 Pizzas to an Unlocked Franchise, you can Claim the
              Machine for that Franchise.
            </p>
            <Badge
              color="red"
              count={<button className="claim-btn">Claim!</button>}
            >
              {' '}
              <img src={franchisesData.at(4).img} style={{ width: 128 }} />
            </Badge>{' '}
            <p>Hurry up! there only 500 Machines available!</p>
            <Divider />
            <p style={{ fontWeight: 'bold' }}>IMPORTANT!</p>
            <p>
              This Machine NFTs contain on chain metadata, and are intended to
              be used in the Pizzapp and Future related Projects. They are not
              ment to be traded on other secondary markets, if you do so, you
              may loose them forever!
            </p>
          </Card>
        </Row>
      </Modal>
      <Modal
        width="800px"
        title="About Luck and Pizza Status"
        visible={openLuckModal}
        style={{ fontFamily: 'Sniglet' }}
        closable={false}
        footer={[
          <Button type="secondary" onClick={handleOkLuckModal}>
            close
          </Button>,
        ]}
      >
        <div>
          <h3 style={{ color: '#ff0278' }}>
            There are 5 posible Status you can get when Baking a Pizza:
          </h3>
          <Row>
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['overcooked']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Overcooked
              </p>
            </div>
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['undercooked']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Undercooked
              </p>
            </div>
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['good']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Good
              </p>
            </div>
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['great']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Great
              </p>
            </div>
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['perfect']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Perfect
              </p>
            </div>
          </Row>
          <h3 style={{ color: '#ff0278' }}>
            There are 2 types of Luck Boosts in Pizzapp:
          </h3>
          <Row>
            <p style={{ padding: 8 }}>Chef's Luck ✨</p>
            <p style={{ padding: 8 }}>Owner's Luck 🤞</p>
          </Row>
          <Divider />
          <h3 style={{ color: '#ff0278' }}>Baking with Owner's Luck 🤞</h3>

          <div
            style={{
              marginRight: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['good']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Good
              </p>
            </div>
            <p>= +2% Boost on your Chef's Luck ✨.</p>
          </div>
          <div
            style={{
              marginRight: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['great']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Great
              </p>
            </div>
            <p>= +4% Boost on your Chef's Luck ✨.</p>
          </div>
          <div
            style={{
              marginRight: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['perfect']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Perfect
              </p>
            </div>
            <p>
              = Golden Pizza! These Pizzas allow you to unlock one of Six
              Franchises.
            </p>
          </div>

          <p>
            Every time you send a Pizza to one of your Franchises, you get a
            permanent +1.2% Boost on your Owner's Luck 🤞
          </p>
          <Divider />
          <h3 style={{ color: '#ff0278' }}>Baking with Chef's Luck ✨</h3>
          <Row>
            <Col span={12}>
              <div
                style={{
                  marginRight: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img src={appIcons['good']} style={{ width: 64 }} />
                  <p
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Good
                  </p>
                </div>
                <p>= +6% Boost on your Chef's Luck ✨.</p>
              </div>
              <div
                style={{
                  marginRight: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img src={appIcons['great']} style={{ width: 64 }} />
                  <p
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Great
                  </p>
                </div>
                <p>= +8% Boost on your Chef's Luck ✨.</p>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  marginRight: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img src={appIcons['overcooked']} style={{ width: 64 }} />
                  <p
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Overcooked
                  </p>
                </div>
                <p>= Loose your acumulated Chef's Luck ✨.</p>
              </div>

              <div
                style={{
                  marginRight: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img src={appIcons['undercooked']} style={{ width: 64 }} />
                  <p
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Undercooked
                  </p>
                </div>
                <p>= Loose your acumulated Chef's Luck ✨.</p>
              </div>
            </Col>
          </Row>

          <div
            style={{
              marginRight: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['perfect']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Perfect
              </p>
            </div>
            <p>
              = Golden Recipe! These recipes allow you to unlock one of Six
              Franchises.
            </p>
            <p>You will loose your acumulated Chef's Luck ✨.</p>
          </div>
        </div>
      </Modal>
      <div className={pageMode == 'white' ? 'my-nfts' : 'my-nfts-black'}>
        {mintData == 'loading' ? (
          <div>
            <Spin tip="Loading Your Machines" indicator={antIcon} />
          </div>
        ) : mintData == '' ? (
          <div>
            {' '}
            <h2
              style={{
                padding: 10,
                color: '#ff0278',
                backgroundColor: pageMode,
                borderRadius: 10,
                display: 'flex',
                justifyContent: 'center',
                fontWeight: 'bold',
              }}
            >{`🤖 Machines Claimed: ${minted}/${maxSupply}`}</h2>
            <h3
              style={{
                padding: 10,
                color: '#ff0278',
                backgroundColor: pageMode,
                borderRadius: 10,
              }}
            >
              You Dont have any Machines Yet, Unlock a Franchsie and Complete it
              to claim your Machine!
            </h3>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
              }}
            >
              {' '}
              <button className="info-btn" onClick={showAboutModal}>
                About Franchises
              </button>
              <button className="info-btn" onClick={showLuckModal}>
                How Luck Works?
              </button>
            </div>
          </div>
        ) : (
          mintData.map((item) => (
            <div
              style={{
                padding: 20,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Row gutter={12}>
                <Col>
                  <Card
                    title="Machine"
                    style={{
                      border: 'none',
                      borderRadius: 10,
                      boxShadow: '6px 6px 0px 6px #ff0278',
                      background: pageMode,
                    }}
                    headStyle={{
                      color: 'white',
                      backgroundColor: '#00bdff',
                      borderTopRightRadius: 10,
                      borderTopLeftRadius: 10,
                      border: 'none',
                    }}
                  >
                    <img
                      style={{ width: 700 }}
                      src={
                        franchisesData.at(item.metadata.charAt(0) - 1)
                          .franchiseImg
                      }
                      alt=""
                    />
                  </Card>
                </Col>
                <Col>
                  <Card
                    title="Pizzas"
                    style={{
                      border: 'none',
                      borderRadius: 10,
                      boxShadow: '6px 6px 0px 6px #ff0278',
                      background: pageMode,
                    }}
                    headStyle={{
                      color: 'white',
                      backgroundColor: '#00bdff',
                      borderTopRightRadius: 10,
                      borderTopLeftRadius: 10,
                      border: 'none',
                    }}
                  >
                    {item.metadata
                      .split(item.metadata.charAt(0))
                      .filter((item) => item != '')
                      .map((recipe) => (
                        <Row>
                          <Col span={12}>
                            {recipe
                              .split(',')
                              .filter((item) => item != '')
                              .map((ingredient) =>
                                ingredient.length > 3 ? (
                                  ''
                                ) : (
                                  <img
                                    className="ingredient-layer-mynfts"
                                    src={
                                      ingredientsImages[
                                        ingredientsCodesReversed[ingredient]
                                      ]
                                    }
                                  />
                                )
                              )}
                            <img
                              width={140}
                              src={ingredientsImages.basePizza}
                            />
                          </Col>
                          <Col span={12}>
                            {recipe
                              .split(',')
                              .filter((item) => item != '')
                              .map((ingredient) =>
                                ingredient.length > 3 ? (
                                  <p
                                    style={{
                                      color:
                                        pageMode == 'white' ? 'black' : 'white',
                                    }}
                                  >
                                    {ingredient.charAt(0).toUpperCase() +
                                      ingredient.slice(1, ingredient.length)}
                                  </p>
                                ) : (
                                  ''
                                )
                              )}
                            {recipe
                              .split(',')
                              .filter((item) => item != '')
                              .map((ingredient) =>
                                ingredient.length > 3 ? (
                                  ''
                                ) : (
                                  <img
                                    style={{ width: 32 }}
                                    src={
                                      ingredientsIcons[
                                        ingredientsCodesReversed[ingredient]
                                      ]
                                    }
                                  />
                                )
                              )}
                          </Col>
                        </Row>
                      ))}
                  </Card>
                </Col>
              </Row>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default MyNFTs;
