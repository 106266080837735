//Toppings
import anchovies from './toppings/v2/anchovies.png';
import bacon from './toppings/v2/bacon.png';
import basil from './toppings/v2/basil.png';
import beef from './toppings/v2/beef.png';
import chicken from './toppings/v2/chicken.png';
import green_pepper from './toppings/v2/green_pepper.png';
import ham from './toppings/v2/ham.png';
import mushrooms from './toppings/v2/mushrooms.png';
import olives from './toppings/v2/olives.png';
import onions from './toppings/v2/onions.png';
import pepperoni from './toppings/v2/pepperoni.png';
import pineapple from './toppings/v2/pineapple.png';
import red_jalapeno from './toppings/v2/red_jalapeno.png';
import red_pepper from './toppings/v2/red_pepper.png';
import shrimp from './toppings/v2/shrimp.png';
import tomato from './toppings/v2/tomato.png';
import yellow_jalapeno from './toppings/v2/yellow_jalapeno.png';
import corn from './toppings/v2/corn.png';
import basePizza from './toppings/v2/base-pizza.png';

//Kitchen
import anchoviesKitchen from './ingredients/anchovies.png';
import baconKitchen from './ingredients/bacon.png';
import basilKitchen from './ingredients/basil.png';
import beefKitchen from './ingredients/beef.png';
import chickenKitchen from './ingredients/chicken.png';
import green_pepperKitchen from './ingredients/green_pepper.png';
import hamKitchen from './ingredients/ham.png';
import mushroomsKitchen from './ingredients/mushrooms.png';
import olivesKitchen from './ingredients/olives.png';
import onionsKitchen from './ingredients/onions.png';
import pepperoniKitchen from './ingredients/pepperoni.png';
import pineappleKitchen from './ingredients/pineapple.png';
import red_jalapenoKitchen from './ingredients/red_jalapeno.png';
import red_pepperKitchen from './ingredients/red_pepper.png';
import shrimpKitchen from './ingredients/shrimp.png';
import tomatoKitchen from './ingredients/tomato.png';
import yellow_jalapenoKitchen from './ingredients/yellow_jalapeno.png';
import cornKitchen from './ingredients/corn.png';

//Icons
import anchoviesIcon from './icons/anchovies.png';
import baconIcon from './icons/bacon.png';
import basilIcon from './icons/basil.png';
import beefIcon from './icons/beef.png';
import chickenIcon from './icons/chicken.png';
import green_pepperIcon from './icons/green_pepper.png';
import hamIcon from './icons/ham.png';
import mushroomsIcon from './icons/mushrooms.png';
import olivesIcon from './icons/olives.png';
import onionsIcon from './icons/onions.png';
import pepperoniIcon from './icons/pepperoni.png';
import pineappleIcon from './icons/pineapple.png';
import red_jalapenoIcon from './icons/red_jalapeno.png';
import red_pepperIcon from './icons/red_pepper.png';
import shrimpIcon from './icons/shrimp.png';
import tomatoIcon from './icons/tomato.png';
import yellow_jalapenoIcon from './icons/yellow_jalapeno.png';
import cornIcon from './icons/corn.png';

import key from './icons/key.png';
import oven from './icons/oven.png';
import locked from './icons/locked.png';
import unlocked from './icons/unlocked.png';
import good_great from './icons/good_great.svg';
import overcooked from './icons/overcooked.svg';
import undercooked from './icons/undercooked.svg';
import perfect from './icons/perfect.svg';
import unlock_stars from './icons/unlock_stars.png';
import delivery from './icons/delivery.png';

const appIcons = {
  key: key,
  oven: oven,
  locked: locked,
  unlocked: unlocked,
  unlock_stars: unlock_stars,
  good: good_great,
  great: good_great,
  overcooked: overcooked,
  undercooked: undercooked,
  perfect: perfect,
  delivery: delivery,
};

const ingredientsImages = {
  anchovies: anchovies,
  bacon: bacon,
  basil: basil,
  beef: beef,
  chicken: chicken,
  green_pepper: green_pepper,
  ham: ham,
  mushrooms: mushrooms,
  olives: olives,
  onions: onions,
  pepperoni: pepperoni,
  pineapple: pineapple,
  red_jalapeno: red_jalapeno,
  red_pepper: red_pepper,
  shrimp: shrimp,
  tomato: tomato,
  yellow_jalapeno: yellow_jalapeno,
  corn: corn,
  basePizza: basePizza,
};

const ingredientsKitchen = {
  anchovies: anchoviesKitchen,
  bacon: baconKitchen,
  basil: basilKitchen,
  beef: beefKitchen,
  chicken: chickenKitchen,
  green_pepper: green_pepperKitchen,
  ham: hamKitchen,
  mushrooms: mushroomsKitchen,
  olives: olivesKitchen,
  onions: onionsKitchen,
  pepperoni: pepperoniKitchen,
  pineapple: pineappleKitchen,
  red_jalapeno: red_jalapenoKitchen,
  red_pepper: red_pepperKitchen,
  shrimp: shrimpKitchen,
  tomato: tomatoKitchen,
  yellow_jalapeno: yellow_jalapenoKitchen,
  corn: cornKitchen,
};

const ingredientsIcons = {
  anchovies: anchoviesIcon,
  bacon: baconIcon,
  basil: basilIcon,
  beef: beefIcon,
  chicken: chickenIcon,
  green_pepper: green_pepperIcon,
  ham: hamIcon,
  mushrooms: mushroomsIcon,
  olives: olivesIcon,
  onions: onionsIcon,
  pepperoni: pepperoniIcon,
  pineapple: pineappleIcon,
  red_jalapeno: red_jalapenoIcon,
  red_pepper: red_pepperIcon,
  shrimp: shrimpIcon,
  tomato: tomatoIcon,
  yellow_jalapeno: yellow_jalapenoIcon,
  corn: cornIcon,
};
//DATA THAT IS IN THE BLOCKCHAIN

//ingredient list with initial prices
/*
const ingredients = [
  { name: 'pepperoni', price: 6.99 },
  { name: 'sausage', price: 4.99 },
  { name: 'beef', price: 3.99 },
  { name: 'ham', price: 2.99 },
  { name: 'bacon', price: 4.99 },
  { name: 'salami', price: 5.99 },
  { name: 'chicken', price: 2.99 },
  { name: 'olives', price: 1.99 },
  { name: 'tomatoes', price: 3.99 },
  { name: 'mushrooms', price: 2.99 },
  { name: 'pineapple', price: 6.99 },
  { name: 'peppers', price: 3.99 },
  { name: 'onions', price: 2.99 },
  { name: 'oregano', price: 1.99 },
  { name: 'basil', price: 2.99 },
  { name: 'anchovies', price: 4.99 },
  { name: 'jalapeno', price: 3.99 },
  { name: 'spinach', price: 2.99 },
];
*/
/*
//market items

const marketItems = [
  {
    price: '1',
    tokenId: '1',
    seller: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    owner: '0x0000000000000000000000000000000000000000',
    tokenUri: 'https-1',
    listed: true,
  },
  {
    price: '1.5',
    tokenId: '2',
    seller: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    owner: '0x0000000000000000000000000000000000000000',
    tokenUri: 'https-2',
    listed: true,
  },
  {
    price: '2',
    tokenId: '3',
    seller: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    owner: '0x0000000000000000000000000000000000000000',
    tokenUri: 'https-3',
    listed: true,
  },
  {
    price: '2.5',
    tokenId: '4',
    seller: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    owner: '0x0000000000000000000000000000000000000000',
    tokenUri: 'https-4',
    listed: false,
  },
  {
    price: '3',
    tokenId: '5',
    seller: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    owner: '0x0A10000000000000000000000000000000000000',
    tokenUri: 'https-5',
    listed: true,
  },
  {
    price: '3',
    tokenId: '6',
    seller: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    owner: '0x0A10000000000000000000000000000000000000',
    tokenUri: 'https-6',
    listed: false,
  },
];

//transaction data
const transactionsData = [
  {
    transferId: { value: '1' },
    tokenId: { value: '4' },
    seller: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    owner: '0x0000000000000000000000000000000000000000',
    feesAddress: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    value: { value: '100000000000000000000' },
    sold: true,
  },
  {
    transferId: { value: '2' },
    tokenId: { value: '5' },
    seller: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    owner: '0x0000000000000000000000000000000000000000',
    feesAddress: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    value: { value: '100000000000000000000' },
    sold: true,
  },
];

//minted items
const mintedItemsData = [
  {
    minter: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    tokenId: { value: '1' },
    URI: 'https-1',
  },
  {
    minter: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    tokenId: { value: '2' },
    URI: 'https-2',
  },
  {
    minter: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    tokenId: { value: '3' },
    URI: 'https-3',
  },
  {
    minter: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    tokenId: { value: '4' },
    URI: 'https-4',
  },
  {
    minter: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    tokenId: { value: '5' },
    URI: 'https-5',
  },
  {
    minter: '0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266',
    tokenId: { value: '6' },
    URI: 'https-6',
  },
];
*/

//Calculation outside the blockchain
//Ingredient Counter
/*
const ingredientsCounter = [
  { name: 'pepperoni', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'sausage', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'beef', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'ham', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'bacon', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'salami', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'chicken', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'olives', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'tomatoes', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'mushrooms', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'pineapple', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'peppers', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'onions', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'oregano', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'basil', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'anchovies', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'jalapeno', count: 0, score: 0, dynamicPrice: 0 },
  { name: 'spinach', count: 0, score: 0, dynamicPrice: 0 },
];
*/
export default {
  ingredientsImages,
  ingredientsIcons,
  ingredientsKitchen,
  appIcons,
};
