import Navbar from './components/Navbar';
import Kitchen from './components/Kitchen';
import PizzaMarket from './components/PizzaMarket';
import MyPizzas from './components/MyPizzas';
import AdminPage from './components/AdminPage';
import Footer from './components/Footer';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import { ethers } from 'ethers';
import { useEffect, useState } from 'react';
import Web3Modal from 'web3modal';

import { nftaddress, pizzappmarketaddress, franchiseaddress } from './config';

import NFT from './_artifacts/NFT.sol/NFT.json';
import PizzappMarket from './_artifacts/PizzappMarket.sol/PizzappMarket.json';
import Franchise from './_artifacts/Franchise.sol/Franchise.json';

import data from './tempData';
import FranchisePage from './components/FranchisePage';

import About from './components/About';
import MyNFTs from './components/MyNFTs.jsx';

function App() {
  const { ingredientsImages, ingredientsIcons, ingredientsKitchen, appIcons } =
    data;

  const [marketItems, setMarketItems] = useState([]);
  const [mintedItems, setMintedItems] = useState([]);

  const [loadingState, setLoadingState] = useState('not-loaded');

  const [currentWallet, setCurrentWallet] = useState('');

  const [admin, setAdmin] = useState('');

  const [maxSupply, setMaxSupply] = useState('');

  const [chefPity, setChefPity] = useState('');
  const [franchisePity, setFranchisePity] = useState('');

  const [ingredients, setIngredients] = useState('');

  const [pageMode, setPageMode] = useState('white');

  const [ovenReady, setOvenReady] = useState(false);

  const [ovenStatus, setOvenStatus] = useState('Oven is on! 🥵');

  const [boughtItems, setBoughtItems] = useState('');

  const [baked, setBaked] = useState('');

  const [providerSwitch, setproviderSwitch] = useState(
    'https://bsc-dataseed.binance.org/',
    { name: 'BSC', chainId: 56 }
  ); //'https://data-seed-prebsc-1-s1.binance.org:8545/',{ name: 'BSC', chainId: 97 }

  const [timeLeft, setTimeLeft] = useState(10);

  let currentNumber = 12;

  function countdown() {
    currentNumber--;
    if (currentNumber > 0) {
      setTimeout(countdown, 1000);
    }
    setTimeLeft(currentNumber);
  }

  let franchises = [
    { name: 'Ludos', value: [] },
    { name: 'CheesyRockets', value: [] },
    { name: 'PizzaXpress', value: [] },
    { name: 'MeltingMoon', value: [] },
    { name: 'SliceOfHeaven', value: [] },
    { name: 'Pizzapp', value: [] },
    { name: 'None', value: [] },
  ];

  const pizzaStatus = ['Overcooked', 'Undercooked', 'Good', 'Great', 'Perfect'];

  const ingredientsCodes = {
    anchovies: 'an',
    bacon: 'bac',
    basil: 'bas',
    beef: 'be',
    chicken: 'chi',
    green_pepper: 'gp',
    ham: 'hm',
    mushrooms: 'msh',
    olives: 'ol',
    onions: 'on',
    pepperoni: 'pe',
    pineapple: 'pi',
    red_jalapeno: 'rj',
    red_pepper: 'rp',
    shrimp: 'shr',
    tomato: 'tom',
    yellow_jalapeno: 'yj',
    corn: 'cor',
  };
  const ingredientsCodesReversed = {
    an: 'anchovies',
    bac: 'bacon',
    bas: 'basil',
    be: 'beef',
    chi: 'chicken',
    gp: 'green_pepper',
    hm: 'ham',
    msh: 'mushrooms',
    ol: 'olives',
    on: 'onions',
    pe: 'pepperoni',
    pi: 'pineapple',
    rj: 'red_jalapeno',
    rp: 'red_pepper',
    shr: 'shrimp',
    tom: 'tomato',
    yj: 'yellow_jalapeno',
    cor: 'corn',
  };
  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        const obj = {
          status: addressArray[0].toString().slice(0, 10) + '...',
          address: addressArray[0],
        };
        return obj;
      } catch (err) {
        return {
          address: '',
          status: '😥 ' + err.message,
        };
      }
    } else {
      return {
        address: '',
        status: (
          <span>
            <p>
              {' '}
              🦊{' '}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  const getCurrentWalletConnected = async () => {
    if (window.ethereum) {
      try {
        const addressArray = await window.ethereum.request({
          method: 'eth_accounts',
        });
        if (addressArray.length > 0) {
          return {
            address: addressArray[0],
            status: addressArray[0].toString().slice(0, 10) + '...',
          };
        } else {
          return {
            address: '',
            status: '🦊 Connect to Metamask',
          };
        }
      } catch (err) {
        return {
          address: '',
          status: '😥 ' + err.message,
        };
      }
    } else {
      return {
        address: '',
        status: (
          <span>
            <p>
              {' '}
              🦊{' '}
              <a target="_blank" href={`https://metamask.io/download.html`}>
                You must install Metamask, a virtual Ethereum wallet, in your
                browser.
              </a>
            </p>
          </span>
        ),
      };
    }
  };

  useEffect(() => {
    setAdmin('0xEEB3A2c8d71D530948d81b546B211d2cD3b04A3C');
    if (currentWallet != '') {
      loadData();
      supply();
      async function supply() {
        setMaxSupply(await getMaxSupply());
      }
    }
  }, [currentWallet]);

  async function loadData() {
    setLoadingState('not-loaded');
    setChefPity('loading');
    setFranchisePity('loading');
    setMintedItems('loading');
    setMarketItems('loading');
    setBoughtItems('loading');
    setBaked('loading');

    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);
    const franchiseContract = new ethers.Contract(
      franchiseaddress,
      Franchise.abi,
      provider
    );
    const marketContract = new ethers.Contract(
      pizzappmarketaddress,
      PizzappMarket.abi,
      provider
    );

    const chefPityData = await tokenContract.getChefsLuck(currentWallet);
    const franchisePityData = await franchiseContract.fetchOwnersLuck(
      currentWallet
    );
    let chefPityNum = await chefPityData.toNumber();
    let franchisePityNum = await franchisePityData.toNumber();

    setChefPity(chefPityNum);
    setFranchisePity(franchisePityNum);

    const ingredientsListData = await tokenContract.getIngredientList();
    setIngredients(ingredientsListData);

    const mintedTotal = await tokenContract.getCurrentMinted();
    setBaked(mintedTotal);

    // const mintedData = await tokenContract.fetchOriginalMinters(); //fetchMinted
    const mintedData = await tokenContract.getMintedItems(currentWallet); //new method
    const mintedItems = await Promise.all(
      mintedData.map(async (i) => {
        //const tokenUri = await tokenContract.tokenURI(i.itemId); // deprecated!!

        let currentOwner = await tokenContract.ownerOf(i); //new method i.itemId
        //NFTonChain changes
        let recipeData = await tokenContract.getRecipe(i); //new method i.itemId
        let nameData = await tokenContract.getRecipeName(i); //new method i.itemId
        let statusData = await tokenContract.getRecipeStatus(i); //new method i.itemId

        //NFTonChain changes
        let item = {
          tokenId: i.toNumber(), //new method i.itemId.toNumber()
          minterAddress: currentWallet, //new method i.minterAddress
          chef: currentWallet, //new method i.minterAddress
          owner: currentOwner,
          name: nameData,
          recipe: recipeData,
          status: statusData,
        };
        return item;
      })
    );
    setMintedItems(mintedItems.reverse());

    //new method
    const boughtItemsData = await marketContract.getBoughtItems(currentWallet);
    const boughtItem = await Promise.all(
      boughtItemsData.map(async (i) => {
        let currentOwner = await tokenContract.ownerOf(i);

        //NFTonChain changes
        let recipeData = await tokenContract.getRecipe(i);
        let nameData = await tokenContract.getRecipeName(i);
        let statusData = await tokenContract.getRecipeStatus(i);

        //NFTonChain changes
        let item = {
          tokenId: i.toNumber(),
          owner: currentOwner,
          name: nameData,
          recipe: recipeData,
          status: statusData,
        };
        return item;
      })
    );

    setBoughtItems(boughtItem);

    const marketItemsdata = await marketContract.fetchMarketItems();

    const marketItems = await Promise.all(
      marketItemsdata.map(async (i) => {
        //const tokenUri = await tokenContract.tokenURI(i.tokenId);
        let currentOwner = await tokenContract.ownerOf(i.tokenId);

        //NFTonChain changes
        let recipeData = await tokenContract.getRecipe(i.tokenId);
        let nameData = await tokenContract.getRecipeName(i.tokenId);
        let statusData = await tokenContract.getRecipeStatus(i.tokenId);

        let price = ethers.utils.formatUnits(i.price.toString(), 'ether');
        //NFTonChain changes
        let item = {
          price,
          itemId: i.itemId.toNumber(),
          tokenId: i.tokenId.toNumber(),
          seller: i.seller,
          owner: currentOwner,
          name: nameData,
          recipe: recipeData,
          listed: i.listed,
          status: statusData,
        };
        return item;
      })
    );
    setMarketItems(marketItems);

    setLoadingState('Loaded');
  }

  const childToParent = (data) => {
    setCurrentWallet(data);
  };

  async function getMaxSupply() {
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);
    const marketContract = new ethers.Contract(
      pizzappmarketaddress,
      PizzappMarket.abi,
      provider
    );
    let maxSupply = await tokenContract.getMaxSupply();
    maxSupply = maxSupply.toString();

    return maxSupply;
  }

  async function getPizzaCount(storeId) {
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const franchiseContract = new ethers.Contract(
      franchiseaddress,
      Franchise.abi,
      provider
    );

    let pizzaCount = await franchiseContract.fetchPizzaCount(storeId);
    pizzaCount = pizzaCount.toString();

    return pizzaCount;
  }

  async function turnOvenOn() {
    //localhost changes
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, signer);

    try {
      setOvenStatus(`Turning On`);
      const transaction = await tokenContract.requestRandomWords();
      await transaction.wait();
      //setOvenReady(true); ////localhost changes FAKE OVEN
      // setOvenStatus(`Getting Hotter!`);
      internalAddressCheck();
      console.log('request succesful!');
    } catch (error) {
      setOvenStatus('');
      console.log('error on request', error);
    }
  }

  async function internalAddressCheck() {
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);

    try {
      setOvenStatus(`Getting Hotter!`);
      countdown();
      const hasNumber = await tokenContract.manualCheck(currentWallet);
      console.log('hasNumber', hasNumber);

      if (hasNumber == true) {
        setOvenReady(true);
        setOvenStatus('Oven Ready to Bake ✔');
      }
      if (hasNumber == false) {
        setOvenReady(false);
      }
    } catch (error) {
      console.log('error on manual check', error);
    }
  }

  async function manualAddressCheck() {
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);

    try {
      const hasNumber = await tokenContract.manualCheck(currentWallet);
      console.log('hasNumber', hasNumber);

      if (hasNumber == true) {
        setOvenReady(true);
        setOvenStatus('Oven Ready to Bake ✔');
      }
      if (hasNumber == false) {
        setOvenReady(false);
      }
    } catch (error) {
      console.log('error on manual check', error);
    }
  }

  async function forceManualAddressCheck(wallet) {
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);

    try {
      const hasNumber = await tokenContract.manualCheck(wallet);
      console.log('hasNumber', hasNumber);

      if (hasNumber == true) {
        setOvenReady(true);
        setOvenStatus('Oven Ready to Bake ✔');
      }
      if (hasNumber == false) {
        setOvenReady(false);
      }
    } catch (error) {
      console.log('error on manual check', error);
    }
  }

  return (
    <div className="app">
      <div>
        <Navbar
          connectWallet={connectWallet}
          getCurrentWalletConnected={getCurrentWalletConnected}
          childToParent={childToParent}
          mintedItems={mintedItems}
          admin={admin}
          maxSupply={maxSupply}
          pageMode={pageMode}
          setPageMode={setPageMode}
          manualAddressCheck={manualAddressCheck}
          forceManualAddressCheck={forceManualAddressCheck}
          baked={baked}
        />
      </div>
      <div>
        <Routes>
          <Route
            path="/"
            element={
              <Kitchen
                currentWallet={currentWallet}
                loadData={loadData}
                maxSupply={maxSupply}
                mintedItems={mintedItems}
                chefPity={chefPity}
                franchisePity={franchisePity}
                ingredients={ingredients}
                ingredientsImages={ingredientsImages}
                ingredientsKitchen={ingredientsKitchen}
                pageMode={pageMode}
                ovenReady={ovenReady}
                turnOvenOn={turnOvenOn}
                manualAddressCheck={manualAddressCheck}
                setOvenStatus={setOvenStatus}
                ovenStatus={ovenStatus}
                ingredientsCodes={ingredientsCodes}
                ingredientsCodesReversed={ingredientsCodesReversed}
                appIcons={appIcons}
                baked={baked}
                providerSwitch={providerSwitch}
                timeLeft={timeLeft}
                loadingState={loadingState}
              />
            }
          ></Route>

          <Route
            path="/market"
            element={
              <PizzaMarket
                marketItems={marketItems}
                mintedItems={mintedItems}
                loadData={loadData}
                maxSupply={maxSupply}
                currentWallet={currentWallet}
                pizzaStatus={pizzaStatus}
                ingredientsImages={ingredientsImages}
                ingredientsIcons={ingredientsIcons}
                pageMode={pageMode}
                loadingState={loadingState}
                ingredientsCodesReversed={ingredientsCodesReversed}
                baked={baked}
                appIcons={appIcons}
              />
            }
          ></Route>
          <Route
            path="/mypizzas"
            element={
              <MyPizzas
                currentWallet={currentWallet}
                mintedItems={mintedItems}
                marketItems={marketItems}
                loadData={loadData}
                pizzaStatus={pizzaStatus}
                ingredientsImages={ingredientsImages}
                ingredientsIcons={ingredientsIcons}
                pageMode={pageMode}
                ingredientsCodesReversed={ingredientsCodesReversed}
                boughtItems={boughtItems}
                appIcons={appIcons}
              />
            }
          ></Route>
          <Route
            path="/admin-pizzapp"
            element={
              <AdminPage
                mintedItems={mintedItems}
                marketItems={marketItems}
                loadData={loadData}
                loadingState={loadingState}
                providerSwitch={providerSwitch}
              />
            }
          ></Route>
          <Route
            path="/franchise"
            element={
              <FranchisePage
                currentWallet={currentWallet}
                mintedItems={mintedItems}
                loadData={loadData}
                loadingState={loadingState}
                franchises={franchises}
                pizzaStatus={pizzaStatus}
                ingredientsImages={ingredientsImages}
                ingredientsIcons={ingredientsIcons}
                franchisePity={franchisePity}
                chefPity={chefPity}
                getPizzaCount={getPizzaCount}
                pageMode={pageMode}
                ingredientsCodesReversed={ingredientsCodesReversed}
                boughtItems={boughtItems}
                appIcons={appIcons}
                providerSwitch={providerSwitch}
              />
            }
          ></Route>
          <Route
            path="/mymachines"
            element={
              <MyNFTs
                currentWallet={currentWallet}
                providerSwitch={providerSwitch}
                ingredientsImages={ingredientsImages}
                ingredientsIcons={ingredientsIcons}
                pageMode={pageMode}
                ingredientsCodesReversed={ingredientsCodesReversed}
                franchises={franchises}
                loadingState={loadingState}
                appIcons={appIcons}
              />
            }
          ></Route>
          <Route
            path="/about"
            element={
              <About
                pageMode={pageMode}
                appIcons={appIcons}
                franchises={franchises}
              />
            }
          ></Route>
        </Routes>
      </div>
      <div>
        <Footer pageMode={pageMode} />
      </div>
    </div>
  );
}

export default App;
