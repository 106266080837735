import { useState, useEffect, Component } from 'react';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  Steps,
  Carousel,
  Spin,
  Divider,
  Badge,
} from 'antd';
import './franchisepage.css';
import 'antd/dist/antd.css';
import { nftaddress, winnersnftaddress, franchiseaddress } from '../config';

import NFT from '../_artifacts/NFT.sol/NFT.json';
import WinnersNFT from '../_artifacts/WinnersNFT.sol/WinnersNFT.json';
import Franchise from '../_artifacts/Franchise.sol/Franchise.json';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';

import lockedFranchise from '../imgs/lockedFranchise.png';
import F_CheesyRockets from '../imgs/F_CheesyRockets.png';
import F_Ludos from '../imgs/F_Ludos.png';
import F_MeltingMoon from '../imgs/F_MeltingMoon.png';
import F_PizzApp from '../imgs/F_PizzApp.png';
import F_PizzaXpress from '../imgs/F_PizzaXpress.png';
import F_SliceOfHeaven from '../imgs/F_SliceOfHeaven.png';

import { LoadingOutlined } from '@ant-design/icons';
import franchise_logo_01 from '../imgs/franchise_logo_01.png';
import franchise_logo_02 from '../imgs/franchise_logo_02.png';
import franchise_logo_03 from '../imgs/franchise_logo_03.png';
import franchise_logo_04 from '../imgs/franchise_logo_04.png';
import franchise_logo_05 from '../imgs/franchise_logo_05.png';
import franchise_logo_06 from '../imgs/franchise_logo_06.png';

import locked_anim from '../animations/locked_anim.json';
import unlock_anim from '../animations/unlock_anim.json';
import send_anim from '../animations/send_anim.json';

import Lottie from 'react-lottie-player';
import background02 from '../imgs/background02.svg';

const FranchisePage = ({
  loadData,
  mintedItems,
  currentWallet,
  loadingState,
  franchises,
  ingredientsImages,
  ingredientsIcons,
  pizzaStatus,
  franchisePity,
  chefPity,
  getPizzaCount,
  pageMode,
  ingredientsCodesReversed,
  boughtItems,
  appIcons,
  providerSwitch,
}) => {
  const [franchiseItems, setFranchiseItems] = useState('');
  const [userKeys, setUserKeys] = useState('');
  const [userBoughtKeys, setuserBoughtKeys] = useState('');

  const [walletAddress, setWalletAddress] = useState('');

  const [selectedFranchise, setSelectedFranchise] = useState();

  const [keyCheck, setKeyCheck] = useState(false);
  const [keyId, setKeyId] = useState(0);

  const [myStores, setMyStores] = useState('');

  const [franchiseA, setFranchiseA] = useState('Locked');
  const [franchiseB, setFranchiseB] = useState('Locked');
  const [franchiseC, setFranchiseC] = useState('Locked');
  const [franchiseD, setFranchiseD] = useState('Locked');
  const [franchiseE, setFranchiseE] = useState('Locked');
  const [franchiseF, setFranchiseF] = useState('Locked');

  const [open, setOpen] = useState(false);
  const [unlockingFinished, setunlockingFinished] = useState(false);
  const [sendToFranchiseFinished, setSendToFranchiseFinished] = useState(false);
  const [modalText, setModalText] = useState('');

  const [openUnlockModal, setOpenUnlockModal] = useState(false);
  const [selectedItem, setselectedItem] = useState('');

  const [currentStep, setCurrentStep] = useState('');
  const [openAboutFranchises, setOpenAboutFranchises] = useState(false);

  const [nextBtn, setNextBtn] = useState(5);
  const [prevBtn, setPrevBtn] = useState(0);

  const [showUnlockSteps, setShowUnlockSteps] = useState(false);

  const [alreadyClaimed, setAlreadyClaimed] = useState('');
  const [storeIdClaim, setstoreIdClaim] = useState('second');

  const { Meta } = Card;
  const { Step } = Steps;

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  const showUnlockModal = (item) => {
    setShowUnlockSteps(false);
    setOpenUnlockModal(true);
    setselectedItem(item);
  };

  const handleOkUnlockModal = () => {
    setOpenUnlockModal(false);
    loadFranchiseData();
  };

  const showAboutModal = () => {
    setOpenAboutFranchises(true);
  };

  const handleOkAboutModal = () => {
    setOpenAboutFranchises(false);
  };

  async function loadFranchiseData() {
    console.log('minted', mintedItems);
    setFranchiseItems('loading');
    setMyStores('loading');
    setuserBoughtKeys('loading');
    setUserKeys('loading');

    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);
    const franchiseContract = new ethers.Contract(
      franchiseaddress,
      Franchise.abi,
      provider
    );
    //new version
    const franchiseItemsData = await franchiseContract.fetchFranchiseItems(
      currentWallet
    );

    const franchiseItem = await Promise.all(
      franchiseItemsData.map(async (i) => {
        let recipeData = await tokenContract.getRecipe(i.tokenId);
        let nameData = await tokenContract.getRecipeName(i.tokenId);

        let item = {
          franchiseId: i.franchiseId.toNumber(),
          itemId: i.itemId.toNumber(),
          storeId: i.storeId.toNumber(),
          tokenId: i.tokenId.toNumber(),
          owner: i.owner,
          franchiseMaster: i.franchiseMaster,
          onFranchise: i.onFranchise,
          recipe: recipeData,
          recipeName: nameData,
        };
        return item;
      })
    );

    setFranchiseItems(franchiseItem);

    const userKeyData = await Promise.all(
      mintedItems.map(async (item) => {
        if (item.owner.toLowerCase() == currentWallet) {
          for (let i = 0; i < franchises.length; i++) {
            let keysResponse = await franchiseContract.isKey(item.tokenId); //nftaddress
            if (keysResponse == true) {
              return item.tokenId;
            }
          }
        }
      })
    );

    setUserKeys(userKeyData);
    const userBoughtKeyData = await Promise.all(
      boughtItems.map(async (item) => {
        if (item.owner.toLowerCase() == currentWallet) {
          for (let i = 0; i < franchises.length; i++) {
            let keysResponse = await franchiseContract.isKey(item.tokenId); //nftaddress
            if (keysResponse == true) {
              return item.tokenId;
            }
          }
        }
      })
    );

    setuserBoughtKeys(userBoughtKeyData);
    getData(franchiseItem);
  }

  useEffect(() => {
    setWalletAddress(currentWallet);
    loadData();
  }, [currentWallet]);

  useEffect(() => {
    if (loadingState == 'Loaded' && mintedItems != '') {
      loadFranchiseData();
    }
  }, [loadingState]);

  async function getData(data) {
    if (franchiseItems.length == 0) setMyStores(0);
    if (data.length > 0) {
      const storeData = await Promise.all(
        data.map(async (item) => {
          if (item.owner.toLowerCase() == walletAddress) {
            let newStore =
              item.storeId.toString() +
              ',' +
              item.franchiseId.toString() +
              ',' +
              (await getPizzaCount(item.storeId)).toString();
            return newStore;
          }
        })
      );

      getFinalStoreData(storeData);
    }
  }

  function getFinalStoreData(data) {
    const filteredStoreData = new Set(data);

    const finalStoreData = [...filteredStoreData];

    const _finalStoreData = finalStoreData.filter((item) => item != undefined);

    const finalStoreItem = _finalStoreData.map((item) => {
      let newStore = {
        storeId: item.at(0),
        franchiseId: item.at(2),
        pizzaCounter: item.at(4),
      };
      return newStore;
    });

    setMyStores(finalStoreItem);
  }

  useEffect(() => {
    if (franchiseItems != 'loading') {
      for (let i = 0; i < franchiseItems.length; i++) {
        franchisesData
          .at(franchiseItems.at(i).franchiseId - 1)
          .setStatus('Locked');
      }
      for (let i = 0; i < franchiseItems.length; i++) {
        if (franchiseItems.at(i).owner.toLowerCase() == walletAddress) {
          franchisesData
            .at(franchiseItems.at(i).franchiseId - 1)
            .setStatus('Unlocked');
        }
      }
    }
  }, [franchiseItems]);

  function unlockFranchise(e, item) {
    setShowUnlockSteps(true);
    e.preventDefault();

    setModalText('Unlocking Franchise Please Wait!');
    setCurrentStep(0);

    setunlockingFinished(false);
    setSendToFranchiseFinished(false);
    transferApprovalToUnlock(item);
  }

  //approval for unlocking franchise
  async function transferApprovalToUnlock(nft) {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    let contract = new ethers.Contract(nftaddress, NFT.abi, signer);

    try {
      let transaction = await contract.franchiseApproval(nft.tokenId);
      await transaction.wait();
      unlock(nft);
    } catch (error) {
      console.log('Error on Transfer Approval to Unlock Franchise', error);
      setModalText('Error on Transfer Approval to Unlock Franchise');
      setunlockingFinished(true);
    }
  }

  //unlock franchise
  async function unlock(nft) {
    setModalText('Unlocking Franchise Please Wait!');
    setCurrentStep(1);

    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    let contract = new ethers.Contract(franchiseaddress, Franchise.abi, signer);
    try {
      let transaction = await contract.unlockFranchise(
        nft.tokenId,
        selectedFranchise + 1
      ); //nftaddress
      await transaction.wait();
      setModalText('Franchise Unlocked!');
      setCurrentStep(2);
      setunlockingFinished(true);
      loadData();
    } catch (error) {
      console.log('Error Unlocking Franchise', error);
      setModalText(<p style={{ color: 'red' }}>Error Unlocking Franchise</p>);
      setunlockingFinished(true);
    }
  }

  //approval for sending to franchise store
  async function transferApproval(e, nft, storeId) {
    e.preventDefault();

    setunlockingFinished(false);
    setSendToFranchiseFinished(false);

    setModalText('Transfering Recipe to Franchise Please Wait!');
    setCurrentStep(0);
    showModal();

    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    let contract = new ethers.Contract(nftaddress, NFT.abi, signer);
    try {
      let transaction = await contract.franchiseApproval(nft.tokenId);
      await transaction.wait();
      transferToStore(nft, storeId);
    } catch (error) {
      console.log('Error on Transfer Approval To Franchise Store', error);
      setModalText(
        <p style={{ color: 'red' }}>
          Error on Transfer Approval To Franchise Store
        </p>
      );
      setSendToFranchiseFinished(true);
    }
  }

  //transfer to store
  async function transferToStore(nft, storeId) {
    setModalText('Transfering Recipe to Franchise Please Wait!');
    setCurrentStep(1);

    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    let contract = new ethers.Contract(franchiseaddress, Franchise.abi, signer);

    try {
      let transaction = await contract.listFranchiseItem(
        nft.tokenId,
        storeId,
        currentWallet
      ); //nftaddress
      await transaction.wait();
      setModalText('Recipe Successfully Transfered to Franchise!');
      setCurrentStep(2);
      setSendToFranchiseFinished(true);
      loadData();
    } catch (error) {
      console.log('Error Transfering To Franchise Store', error);
      setModalText(
        <p style={{ color: 'red' }}>Error Transfering To Franchise Store</p>
      );
      setSendToFranchiseFinished(true);
    }
  }

  //check if key
  async function isKey(e, nft) {
    e.preventDefault();

    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const franchiseContract = new ethers.Contract(
      franchiseaddress,
      Franchise.abi,
      provider
    );
    try {
      const response = await franchiseContract.isKey(nft.tokenId); //nftaddress
      console.log(response);
      setKeyCheck(response);
      setKeyId(nft.tokenId);
    } catch (error) {
      console.log('Error on Checcking for Keys', error);
    }
  }

  function selectFranchise(e, id) {
    e.preventDefault();
    setSelectedFranchise(id);
    setKeyCheck(false);
    isKey(e, selectedItem);
  }
  function selectFranchiseCarousel(id) {
    setSelectedFranchise(id);
    setKeyCheck(false);
  }

  const franchisesData = [
    {
      title: franchises.at(0).name,
      img: franchise_logo_01,
      id: 0,
      status: franchiseA,
      setStatus: setFranchiseA,
      franchiseImg: F_Ludos,
    },
    {
      title: franchises.at(1).name,
      img: franchise_logo_02,
      id: 1,
      status: franchiseB,
      setStatus: setFranchiseB,
      franchiseImg: F_CheesyRockets,
    },
    {
      title: franchises.at(2).name,
      img: franchise_logo_03,
      id: 2,
      status: franchiseC,
      setStatus: setFranchiseC,
      franchiseImg: F_PizzaXpress,
    },
    {
      title: franchises.at(3).name,
      img: franchise_logo_04,
      id: 3,
      status: franchiseD,
      setStatus: setFranchiseD,
      franchiseImg: F_MeltingMoon,
    },
    {
      title: franchises.at(4).name,
      img: franchise_logo_05,
      id: 4,
      status: franchiseE,
      setStatus: setFranchiseE,
      franchiseImg: F_SliceOfHeaven,
    },
    {
      title: franchises.at(5).name,
      img: franchise_logo_06,
      id: 5,
      status: franchiseF,
      setStatus: setFranchiseF,
      franchiseImg: F_PizzApp,
    },
  ];
  const contentStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#ff0278',
    textAlign: 'center',
    lineHeight: '10px',
  };
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return <RightCircleFilled className="arrow-right" onClick={onClick} />;
  };

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return <LeftCircleFilled className="arrow-left" onClick={onClick} />;
  };

  const settings = {
    nextArrow: <SampleNextArrow className="arrow-left" />,
    prevArrow: <SamplePrevArrow className="arrow-right" />,
  };

  async function getMintingData(e, storeId) {
    e.preventDefault();

    if ((await claimStatus(storeId)) == true) {
      setAlreadyClaimed(true);

      setstoreIdClaim(storeId);
    }
    if ((await claimStatus(storeId)) == false) {
      const mintingData = franchiseItems.map((item) => {
        if (
          item.owner.toLowerCase() == walletAddress &&
          item.storeId == storeId
        ) {
          let newItem = [item.franchiseId, item.recipe, item.recipeName];

          return newItem.toString();
        } else {
          return '';
        }
      });
      const _mintingData = mintingData.filter((item) => item != '');
      const _mintingDataString = _mintingData.toString();

      mintWinner(_mintingDataString, storeId);
    }
  }

  function prev(e) {
    e.preventDefault();
    if (prevBtn != 0) {
      setNextBtn(nextBtn - 5);
      setPrevBtn(prevBtn - 5);
    }
  }

  function next(e) {
    e.preventDefault();
    if (nextBtn < franchiseItems.length) {
      setNextBtn(nextBtn + 5);
      setPrevBtn(prevBtn + 5);
    }
  }

  async function mintWinner(data, storeId) {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    //create token
    let contract = new ethers.Contract(
      winnersnftaddress,
      WinnersNFT.abi,
      signer
    );

    try {
      let transaction = await contract.mint(data, storeId);
      await transaction.wait();
    } catch (error) {
      console.log('Error my Error', error);
    }
    loadData();
  }

  async function claimStatus(storeId) {
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const contract = new ethers.Contract(
      winnersnftaddress,
      WinnersNFT.abi,
      provider
    );
    try {
      const claim = await contract.getClaimStatus(storeId); //new method

      return claim;
    } catch (error) {
      console.log('Error on Checcking for Keys', error);
    }
  }

  return (
    <div
      className={
        pageMode == 'white' ? 'franchise-page' : 'franchise-page-black'
      }
      style={{
        backgroundImage: `url(${background02})`,
        backgroundSize: '30%',
      }}
    >
      <Modal
        title="About Franchises"
        width="1024px"
        visible={openAboutFranchises}
        style={{ fontFamily: 'Sniglet' }}
        closable={false}
        footer={[
          <Button type="primary" onClick={handleOkAboutModal}>
            Close
          </Button>,
        ]}
      >
        <Row>
          <Card
            title="Franchises & Status"
            bordered={false}
            headStyle={{
              backgroundColor: '#ff0278',
              color: 'white',
              borderRadius: 10,
            }}
          >
            {' '}
            <h3 style={{ color: '#ff0278' }}>
              There are 5 posible Status you can get when Baking a Pizza:
            </h3>
            <Row>
              <Col span={12}>
                <Row>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['overcooked']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Overcooked
                    </p>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['undercooked']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Undercooked
                    </p>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['good']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Good
                    </p>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['great']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Great
                    </p>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['perfect']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Perfect
                    </p>
                  </div>
                </Row>
              </Col>
              <Col span={12}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={appIcons['perfect']}
                    style={{ width: 64, paddingBottom: 20 }}
                  />
                  <p style={{ paddingTop: 20 }}>
                    If you Bake a Perfect Pizza, you can use it to Unlock one of
                    the Six Franchsises.
                  </p>
                </div>
              </Col>
            </Row>
            <h3 style={{ color: '#ff0278' }}>The Franchsies:</h3>
            <div style={{ display: 'flex' }}>
              {' '}
              {franchisesData.map((data) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 12,

                    alignItems: 'center',
                  }}
                >
                  <img src={data.img} style={{ width: 128 }} />

                  <p>{`${data.title}`}</p>
                </div>
              ))}
            </div>
            <div style={{ display: 'flex' }}>
              <Badge color="blue" count={`Locked Franchise`}>
                <img src={lockedFranchise} width={420} alt="" />
              </Badge>
              <Badge color="green" count={`Unlocked Franchise`}>
                <img src={F_SliceOfHeaven} width={420} alt="" />{' '}
              </Badge>
            </div>
          </Card>
        </Row>
        <Row>
          <Card
            title="The Machine & Luck"
            bordered={false}
            headStyle={{
              backgroundColor: '#ff0278',
              color: 'white',
              borderRadius: 10,
            }}
          >
            {' '}
            <p>
              You can Send up to 5 Pizzas to each Franchise, each Pizza gives
              you a permanent +2% Boost on your 🤞Owner's Luck.
            </p>
            <Badge color="green" count={`🍕 2`}>
              {' '}
              <img src={franchisesData.at(4).img} style={{ width: 128 }} />
            </Badge>
            <p>
              Once you sent 5 Pizzas to an Unlocked Franchise, you can Claim the
              Machine for that Franchise.
            </p>
            <Badge
              color="red"
              count={<button className="claim-btn">Claim!</button>}
            >
              {' '}
              <img src={franchisesData.at(4).img} style={{ width: 128 }} />
            </Badge>{' '}
            <p>Hurry up! there only 500 Machines available!</p>
            <Divider />
            <p style={{ fontWeight: 'bold' }}>IMPORTANT!</p>
            <p>
              This Machine NFTs contain on chain metadata, and are intended to
              be used in the Pizzapp and Future related Projects. They are not
              ment to be traded on other secondary markets, if you do so, you
              may loose them forever!
            </p>
          </Card>
        </Row>
      </Modal>
      <Modal
        title="Send Recipe to Franchise"
        visible={open}
        style={{ fontFamily: 'Sniglet' }}
        closable={false}
        footer={[
          unlockingFinished == true || sendToFranchiseFinished == true ? (
            <div>
              <p>{modalText}</p>
              <Button type="primary" onClick={handleOk}>
                Close
              </Button>
            </div>
          ) : (
            'Please interact with your wallet when prompted 👉'
          ),
        ]}
      >
        <div>
          {' '}
          <Lottie
            loop
            animationData={send_anim}
            play
            style={{ height: '100%' }}
          />{' '}
          <Steps direction="vertical" current={currentStep}>
            <Step
              title="Approval"
              description={
                currentStep == 0 ? (
                  <Spin
                    tip="Approving Contract Interaction, Please Wait!"
                    indicator={antIcon}
                  />
                ) : (
                  ''
                )
              }
            />
            <Step
              title="Transfer"
              description={
                currentStep == 1 ? (
                  <Spin
                    tip="Transfering Recipe to Franchise, Please Wait!"
                    indicator={antIcon}
                  />
                ) : (
                  ''
                )
              }
            />
            <Step
              title="Done!"
              description="Recipe Successfully Transfered to Franchise!"
            />
          </Steps>
        </div>
      </Modal>
      <Modal
        style={{ fontFamily: 'Sniglet' }}
        width={1024}
        title="Unlock Franchises"
        visible={openUnlockModal}
        closable={false}
        footer={
          <div>
            <p>{modalText}</p>
            <Button type="primary" onClick={handleOkUnlockModal}>
              Close
            </Button>
          </div>
        }
      >
        <Row>
          <Row>
            <Card
              title="Select the Franchise you want to Unlock!"
              style={{ width: 1024 }}
              bordered={false}
            >
              <Row>
                {franchisesData.map((data) => (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 12,

                      alignItems: 'center',
                    }}
                  >
                    <img src={data.img} style={{ width: 128 }} />
                    <img
                      src={appIcons[data.status.toLowerCase()]}
                      style={{ width: 64 }}
                    />
                    <p>{`${data.title}`}</p>
                    <button
                      className="send-btn"
                      onClick={(e) => selectFranchise(e, data.id)}
                    >
                      Select
                    </button>
                  </div>
                ))}
              </Row>
            </Card>
          </Row>
          <Row>
            <Col span={12}>
              <Card bordered={false}>
                {franchisesData.at(selectedFranchise).status == 'Locked' ? (
                  showUnlockSteps == true ? (
                    <Steps direction="vertical" current={currentStep}>
                      <Step
                        title="Approval"
                        description={
                          currentStep == 0 ? (
                            <Spin
                              tip="Approving Interaction with contract, Please Wait!"
                              indicator={antIcon}
                            />
                          ) : (
                            ''
                          )
                        }
                      />
                      <Step
                        title="Unlocking"
                        description={
                          currentStep == 1 ? (
                            <Spin
                              tip="Unlocking Franchise Please Wait!"
                              indicator={antIcon}
                            />
                          ) : (
                            ''
                          )
                        }
                      />
                      <Step
                        title="Done!"
                        description="Franchise Unlocked! Please Close the Pop Up window."
                      />
                    </Steps>
                  ) : (
                    ''
                  )
                ) : (
                  <div> 🚨 Franchise Alredy Unlocked!</div>
                )}
                {keyCheck == true && keyId == selectedItem.tokenId ? (
                  <div>
                    <button
                      className="unlock-btn"
                      onClick={(e) => unlockFranchise(e, selectedItem)}
                    >
                      <h3 style={{ color: 'white', marginTop: 10 }}>{`Unlock: ${
                        franchises.at(selectedFranchise).name
                      }`}</h3>
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </Card>
            </Col>

            <Col span={12}>
              {' '}
              <div
                style={{
                  display: 'display',
                  justifyContent: 'center',
                }}
              >
                {showUnlockSteps == true ? (
                  currentStep == 2 ? (
                    <div>
                      <Lottie
                        loop
                        animationData={unlock_anim}
                        play
                        style={{ height: '100%' }}
                      />
                    </div>
                  ) : (
                    <Lottie
                      loop
                      animationData={locked_anim}
                      play
                      style={{ height: '100%' }}
                    />
                  )
                ) : (
                  ''
                )}
              </div>
            </Col>
          </Row>
        </Row>
      </Modal>

      <Row>
        <Col span={10}>
          <Card
            title={`The Franchises: 🍕 ${
              franchises.at(selectedFranchise).name
            }`}
            bordered={false}
            style={{
              margin: 10,
              borderRadius: 16,
              backgroundColor: pageMode,
              boxShadow: '6px 6px 0px 6px #00bdff',
              color: '#ff0278',
            }}
            headStyle={{
              backgroundColor: '#ff0278',
              color: 'white',
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              border: 'none',
            }}
          >
            <Carousel
              afterChange={(currentSlide) =>
                selectFranchiseCarousel(currentSlide)
              }
              arrows
              {...settings}
              style={{ margin: 0 }}
            >
              {franchisesData.map((data) => (
                <div>
                  <div style={contentStyle}>
                    {data.status == 'Unlocked' ? (
                      <img
                        style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          width: 540,
                        }}
                        src={data.franchiseImg}
                        alt=""
                      />
                    ) : (
                      <img
                        style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          width: 540,
                        }}
                        src={lockedFranchise}
                        alt=""
                      />
                    )}
                    <div style={{ padding: 20 }}></div>
                  </div>
                </div>
              ))}
            </Carousel>
          </Card>
        </Col>
        <Col span={14}>
          <Card
            className="top-luck"
            bordered={false}
            style={{
              margin: 10,
              borderRadius: 10,
              backgroundColor: '#444444',
              color: 'white',
              boxShadow: '0px 0px 0px -9px rgba(0,0,0,0.6)',
            }}
            headStyle={{
              color: 'white',
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              border: 'none',
            }}
          >
            <Meta
              style={{
                justifyContent: 'center',
                opacity: 100,
                color: 'white',
              }}
              title={
                <div className="chef-details">
                  <p>{`👨‍🍳Chef: ${currentWallet.slice(0, 16) + '...'}`}</p>
                  <p style={{ paddingLeft: 10 }}>
                    {chefPity == 'loading' ? (
                      <Spin tip="Loading ✨Chef's Luck" indicator={antIcon} />
                    ) : (
                      `✨Chef's Luck: +${chefPity / 10}% `
                    )}
                  </p>
                  <p style={{ paddingLeft: 10 }}>
                    {franchisePity == 'loading' ? (
                      <Spin
                        tip="Loading 🤞Owner's Luck: +0%"
                        indicator={antIcon}
                      />
                    ) : (
                      `🤞Owner's Luck: +${franchisePity / 10}%`
                    )}
                  </p>
                </div>
              }
            />
          </Card>
          <div
            style={{
              display: 'flex',
              justifyContent: 'left',

              margin: 20,
            }}
          >
            {' '}
            <Row gutter={24}>
              <Col span={8}>
                <button className="about-btn" onClick={showAboutModal}>
                  About Franchises
                </button>
              </Col>
              <Col span={16}>
                {' '}
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  {myStores != '' && myStores != 'loading'
                    ? myStores?.map((store) =>
                        store['pizzaCounter'] == 5 ? (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              alignContent: 'center',
                              width: 96,
                              backgroundColor: 'white',
                              borderRadius: 10,
                              marginRight: 10,
                              marginLeft: 10,
                            }}
                          >
                            {alreadyClaimed == true &&
                            storeIdClaim == store['storeId'] ? (
                              <Badge color="blue" count={'Claimed!'}>
                                {' '}
                                <img
                                  src={
                                    franchisesData.at(store['franchiseId'] - 1)
                                      .img
                                  }
                                  style={{ width: '100%' }}
                                />
                              </Badge>
                            ) : (
                              <Badge
                                color="blue"
                                count={
                                  <button
                                    className="claim-btn"
                                    onClick={(e) =>
                                      getMintingData(e, store['storeId'])
                                    }
                                  >
                                    Claim!
                                  </button>
                                }
                              >
                                {' '}
                                <img
                                  src={
                                    franchisesData.at(store['franchiseId'] - 1)
                                      .img
                                  }
                                  style={{ width: '100%' }}
                                />
                              </Badge>
                            )}
                          </div>
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'center',
                              width: 96,
                              backgroundColor: 'white',
                              borderRadius: 10,
                              marginRight: 10,
                            }}
                          >
                            <Badge
                              color="green"
                              count={`🍕 ${store['pizzaCounter']}`}
                            >
                              {' '}
                              <img
                                src={
                                  franchisesData.at(store['franchiseId'] - 1)
                                    .img
                                }
                                style={{ width: '100%' }}
                              />
                            </Badge>
                          </div>
                        )
                      )
                    : ''}
                </div>
              </Col>
            </Row>
          </div>

          <Card
            title="Franchise Recipes"
            bordered={false}
            style={{
              borderRadius: 16,
              backgroundColor: pageMode,
              margin: 10,
              boxShadow: '6px 6px 0px 6px #00bdff',
              color: '#ff0278',
            }}
            headStyle={{
              color: 'white',
              backgroundColor: '#ff0278',
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              border: 'none',
            }}
          >
            <Row>
              {franchiseItems == 'loading' ? (
                <Spin tip="Loading Franchise Recipes" indicator={antIcon} />
              ) : franchiseItems == '' ? (
                ''
              ) : (
                franchiseItems.slice(prevBtn, nextBtn).map((item) =>
                  item.owner.toLowerCase() == walletAddress ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <p style={{ paddingRight: 10 }}>⭐</p>
                      <p
                        style={{
                          paddingRight: 10,
                        }}
                      >
                        Franchise:
                      </p>
                      <p
                        style={{
                          paddingRight: 10,
                          fontFamily: 'Fredoka One',
                        }}
                      >
                        {franchises.at(item.franchiseId - 1).name}
                      </p>
                      <p
                        style={{
                          paddingRight: 10,
                        }}
                      >
                        Recipe Name:
                      </p>
                      <p
                        style={{
                          paddingRight: 10,
                          fontFamily: 'Fredoka One',
                        }}
                      >
                        {item.recipeName.toUpperCase()}
                      </p>
                      <p style={{ paddingRight: 10 }}>
                        {`ID: ${item.tokenId}`}
                      </p>
                      <p
                        style={{
                          paddingRight: 10,
                          fontFamily: 'Fredoka One',
                        }}
                      >
                        Recipe:
                      </p>
                      <p style={{ paddingRight: 10 }}>
                        {Object.values(item)
                          .at(7)
                          .split(',')
                          .map((ing) => {
                            return (
                              <img
                                src={
                                  ingredientsIcons[
                                    ingredientsCodesReversed[ing]
                                  ]
                                }
                                className="small-icons-franchise"
                              />
                            );
                          })}
                      </p>
                    </div>
                  ) : (
                    ''
                  )
                )
              )}
            </Row>
            <div style={{ display: 'flex' }}>
              {' '}
              <button className="next-btn" onClick={(e) => prev(e)}>
                {'<'}
              </button>
              <button className="next-btn" onClick={(e) => next(e)}>
                {'>'}
              </button>
            </div>
          </Card>
          <p
            style={{
              margin: 10,
              padding: 10,
              background: pageMode,
              borderRadius: 10,
              width: '100%',
              color: 'gray',
            }}
          >
            Franchises may take longer to load than others parts of the site,
            please be patient…
          </p>
        </Col>
      </Row>
      <Row>
        <Card
          title="My Recipes"
          bordered={false}
          style={{
            margin: 10,
            borderRadius: 16,
            backgroundColor: pageMode,
            boxShadow: '6px 6px 0px 6px #00bdff',
            width: '100%',
          }}
          headStyle={{
            color: 'white',
            backgroundColor: '#ff0278',
            borderTopRightRadius: 10,
            borderTopLeftRadius: 10,
            border: 'none',
          }}
        >
          <Row>
            {mintedItems == 'loading' ? (
              <Spin tip="Loading Your Recipes" indicator={antIcon} />
            ) : (
              mintedItems.map((item) =>
                item.owner.toLowerCase() == walletAddress ? (
                  <Row>
                    <Card
                      className={
                        item.status == 4 ? 'wrapper-franchise' : 'regular'
                      }
                      cover={
                        item.status == 4 ? (
                          <div className="ingredient-key">
                            {userKeys == 'loading' ? (
                              <Spin tip="Loading Key" indicator={antIcon} />
                            ) : userKeys?.includes(item.tokenId) ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexDirection: 'column',
                                }}
                              >
                                <button
                                  className="key"
                                  style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '15px',
                                  }}
                                  onClick={(e) => showUnlockModal(item)}
                                >
                                  <div className="items-titles">
                                    <img
                                      src={appIcons['key']}
                                      alt="key"
                                      style={{ width: 140, padding: 10 }}
                                    />
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                      }}
                                    ></div>
                                    <p>{`${item.name.toUpperCase()}`}</p>
                                    <p
                                      style={{ fontSize: 'small' }}
                                    >{`ID: ${item.tokenId}`}</p>
                                  </div>
                                </button>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          <Row>
                            <Col span={11}>
                              {Object.values(item)
                                .at(5)
                                .split(',')
                                .map((ing) => {
                                  return (
                                    <img
                                      className="ingredient-layer-franchise"
                                      src={
                                        ingredientsImages[
                                          ingredientsCodesReversed[ing]
                                        ]
                                      }
                                    />
                                  );
                                })}
                              <img
                                width={140}
                                src={ingredientsImages.basePizza}
                                style={{ padding: 5 }}
                              />
                              <div style={{ paddingLeft: 10, paddingTop: 10 }}>
                                <div className="items-titles">
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <div>
                                      <p style={{ minWidth: 64 }}>
                                        {item.name.toUpperCase()}
                                      </p>
                                      <p
                                        style={{ fontSize: 'small' }}
                                      >{`ID: ${item.tokenId}`}</p>
                                    </div>

                                    <img
                                      src={
                                        appIcons[
                                          pizzaStatus
                                            .at(item.status)
                                            .toLowerCase()
                                        ]
                                      }
                                      style={{ width: 64, height: 64 }}
                                    />
                                  </div>
                                </div>
                              </div>
                              {myStores == 'loading' ? (
                                <Spin
                                  tip="Loading Franchises Keys"
                                  indicator={antIcon}
                                />
                              ) : myStores != '' ? (
                                myStores?.map((store) =>
                                  store['pizzaCounter'] == 5 ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingLeft: 20,
                                      }}
                                    >
                                      {`${
                                        franchises.at(store['franchiseId'] - 1)
                                          .name
                                      } Full!`}
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        margin: 10,
                                        zIndex: 1,
                                        color: 'white',
                                        display: 'inline-block',
                                      }}
                                    >
                                      {' '}
                                      <button
                                        className="send-btn"
                                        onClick={(e) =>
                                          transferApproval(
                                            e,
                                            item,
                                            store['storeId']
                                          )
                                        }
                                      >
                                        {`Send to: ${
                                          franchises.at(
                                            store['franchiseId'] - 1
                                          ).name
                                        }`}
                                      </button>
                                    </div>
                                  )
                                )
                              ) : (
                                ''
                              )}
                            </Col>

                            <Col span={13}></Col>
                          </Row>
                        )
                      }
                      bordered={false}
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 10,
                        borderRadius: 16,
                        boxShadow:
                          pageMode == 'white'
                            ? '0px 0px 20px -9px rgba(0, 0, 0,0.6)'
                            : '0px 0px 20px 0px rgba(255, 2, 120,1)',
                      }}
                    ></Card>
                  </Row>
                ) : (
                  ''
                )
              )
            )}
            {boughtItems == 'loading' ? (
              <Spin tip="Loading Your Owned Recipes" indicator={antIcon} />
            ) : boughtItems == '' ? (
              ''
            ) : (
              boughtItems.map((item) =>
                item.owner.toLowerCase() == walletAddress ? (
                  <Row>
                    <Card
                      className={item.status == 4 ? 'wrapper' : 'regular'}
                      cover={
                        item.status == 4 ? (
                          <div className="ingredient-key">
                            {userBoughtKeys == 'loading' ? (
                              <Spin tip="Loading Key" indicator={antIcon} />
                            ) : userBoughtKeys?.includes(item.tokenId) ? (
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  flexDirection: 'column',
                                }}
                              >
                                <Button
                                  className="key"
                                  style={{
                                    background: 'none',
                                    border: 'none',
                                    fontSize: '15px',
                                  }}
                                  onClick={(e) => showUnlockModal(item)}
                                >
                                  <div className="items-titles">
                                    <img
                                      src={appIcons['key']}
                                      alt="logo"
                                      style={{ width: 140, padding: 10 }}
                                    />
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                      }}
                                    ></div>
                                    <p>{`${item.name.toUpperCase()}`}</p>
                                    <p
                                      style={{ fontSize: 'small' }}
                                    >{`ID: ${item.tokenId}`}</p>
                                  </div>
                                </Button>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          <Row>
                            <Col span={11}>
                              {Object.values(item)
                                .at(3)
                                .split(',')
                                .map((ing) => {
                                  return (
                                    <img
                                      className="ingredient-layer-franchise"
                                      src={
                                        ingredientsImages[
                                          ingredientsCodesReversed[ing]
                                        ]
                                      }
                                    />
                                  );
                                })}
                              <img
                                width={140}
                                src={ingredientsImages.basePizza}
                                style={{ padding: 5 }}
                              />
                              <div style={{ paddingLeft: 10, paddingTop: 10 }}>
                                <div className="items-titles">
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      // alignItems: 'center',
                                    }}
                                  >
                                    <div>
                                      <p style={{ minWidth: 64 }}>
                                        {item.name.toUpperCase()}
                                      </p>
                                      <p
                                        style={{ fontSize: 'small' }}
                                      >{`ID: ${item.tokenId}`}</p>
                                    </div>
                                    <img
                                      src={
                                        appIcons[
                                          pizzaStatus
                                            .at(item.status)
                                            .toLowerCase()
                                        ]
                                      }
                                      style={{ width: 64, height: 64 }}
                                    />
                                  </div>
                                  <p
                                    style={{ fontSize: 'small' }}
                                  >{`ID: ${item.tokenId}`}</p>
                                </div>
                              </div>
                              {myStores == 'loading' ? (
                                <Spin
                                  tip="Loading Franchise Keys"
                                  indicator={antIcon}
                                />
                              ) : myStores != '' ? (
                                myStores?.map((store) =>
                                  store['pizzaCounter'] == 5 ? (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        paddingLeft: 20,
                                      }}
                                    >
                                      {`${
                                        franchises.at(store['franchiseId'] - 1)
                                          .name
                                      } Full!`}
                                    </div>
                                  ) : (
                                    <div
                                      style={{
                                        margin: 10,
                                        zIndex: 1,
                                        color: 'white',
                                        display: 'inline-block',
                                      }}
                                    >
                                      {' '}
                                      <button
                                        className="send-btn"
                                        onClick={(e) =>
                                          transferApproval(
                                            e,
                                            item,
                                            store['storeId']
                                          )
                                        }
                                      >
                                        {`Send to: ${
                                          franchises.at(
                                            store['franchiseId'] - 1
                                          ).name
                                        }`}
                                      </button>
                                    </div>
                                  )
                                )
                              ) : (
                                ''
                              )}
                            </Col>

                            <Col span={13}></Col>
                          </Row>
                        )
                      }
                      bordered={false}
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 10,
                        borderRadius: 16,
                        boxShadow:
                          pageMode == 'white'
                            ? '0px 0px 20px -9px rgba(0, 0, 0,0.6)'
                            : '0px 0px 20px 0px rgba(255, 2, 120,1)',
                      }}
                    ></Card>
                  </Row>
                ) : (
                  ''
                )
              )
            )}
          </Row>
        </Card>
      </Row>
    </div>
  );
};

export default FranchisePage;
