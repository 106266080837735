import React from 'react';
import './kitchen.css';
import { Card, Row, Col, Button, Divider, Modal, Steps, Spin } from 'antd';
import 'antd/dist/antd.css';
import { ethers } from 'ethers';
import { useState, useEffect } from 'react';
import Web3Modal from 'web3modal';
import { nftaddress } from '../config';
import NFT from '../_artifacts/NFT.sol/NFT.json';
import { Link } from 'react-router-dom';
import logo from '../imgs/LogoV1.svg';
import { LoadingOutlined } from '@ant-design/icons';
import oven_anim from '../animations/oven_anim.json';
import ovenReady_anim from '../animations/ovenReady_anim.json';
import Lottie from 'react-lottie-player';
import background02 from '../imgs/background02.svg';
import basePizzaKitchen from '../toppings/v2/base-pizza.svg';

const Kitchen = ({
  currentWallet,
  loadData,
  chefPity,
  franchisePity,
  ingredients,
  ingredientsImages,
  ingredientsKitchen,
  pageMode,
  ovenReady,
  turnOvenOn,
  manualAddressCheck,
  ovenStatus,
  setOvenStatus,
  ingredientsCodes,
  ingredientsCodesReversed,
  appIcons,
  baked,
  providerSwitch,
  timeLeft,
  loadingState,
}) => {
  const { Meta } = Card;
  const { Step } = Steps;

  const [currentRecipe, setCurrentRecipe] = useState([]);
  const [currentRecipeName, setCurrentRecipeName] = useState('');
  const [wallet, setWallet] = useState('');
  const [kitchenStatus, setKitchenStatus] = useState('');
  const [nameErrorLabel, setNameErrorLabel] = useState('No Recipe Name!');

  const [open, setOpen] = useState(false);
  const [mintingFinished, setMintingFinished] = useState(false);
  const [modalText, setModalText] = useState('');
  const [currentStep, setCurrentStep] = useState('');
  const [currentFunction, setCurrentFunction] = useState('');

  const [openLuckModal, setOpenLuckModal] = useState(false);

  const [message, setMessage] = useState('');

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  async function isKitchenOpen() {
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);

    const _kitchenStatus = await tokenContract.getKitchenStatus();

    return _kitchenStatus;
  }

  const showModal = () => {
    setModalText(
      'Your Pizza is in the Oven, Please Wait. Do not Close or Refresh the Page!'
    );
    setCurrentStep(0);
    setOpen(true);
  };

  const showLuckModal = () => {
    setOpenLuckModal(true);
  };
  const handleOkLuckModal = () => {
    setOpenLuckModal(false);
  };

  useEffect(() => {
    setWallet(currentWallet);
    loadData();
    manualAddressCheck();
  }, [currentWallet]);

  //Manual Block for First 1000 RNG Requests
  useEffect(() => {
    callIsKitchen();
    async function callIsKitchen() {
      if ((await isKitchenOpen()) == false) {
        setKitchenStatus('closed');
      }
      if ((await isKitchenOpen()) == true) {
        setKitchenStatus('open');
      }
    }
  }, [baked.length, loadingState]);

  async function createItem() {
    if (!wallet || !currentRecipeName || !currentRecipe) return;
    setMintingFinished(false);
    setCurrentFunction('mint');
    showModal();
    mintItem(currentRecipe.toString(), currentRecipeName);
  }

  async function createItemWithPity() {
    if (!wallet || !currentRecipeName || !currentRecipe) return;
    setMintingFinished(false);
    setCurrentFunction('mintWithPity');

    showModal();
    mintItemWithPity(currentRecipe.toString(), currentRecipeName);
  }

  //NFTonChain testing
  async function mintItem(recipe, name) {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    //create token
    let contract = new ethers.Contract(nftaddress, NFT.abi, signer);
    // let mintingPrice = await contract.getMintingPrice();
    //mintingPrice = mintingPrice.toString();

    try {
      let transaction = await contract.mintTokenWithChefsLuck(recipe, name, {
        value: ethers.utils.parseEther('0.04'),
      });
      let tx = await transaction.wait();
      let event = tx.events[0];
      let value = event.args[2];
      let tokenId = value.toNumber();
      setModalText('Done! Your Pizza is ready');
      setCurrentStep(1);
      setMintingFinished(true);
      manualAddressCheck();
      setOvenStatus('');
    } catch (error) {
      console.log('Error my Error', error);
      setModalText('There was an error with your transaction!');
      setMintingFinished(true);
    }
    loadData();
    setCurrentRecipe([]);
    setCurrentRecipeName('');
    document.getElementById('nameInput').value = '';
  }

  async function mintItemWithPity(recipe, name) {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    //create token
    let contract = new ethers.Contract(nftaddress, NFT.abi, signer);
    //let mintingPrice = await contract.getMintingPrice();
    // mintingPrice = mintingPrice.toString();
    try {
      let transaction = await contract.mintWithOwnersLuck(recipe, name, {
        value: ethers.utils.parseEther('0.04'),
      });
      let tx = await transaction.wait();
      let event = tx.events[0];
      let value = event.args[2];
      let tokenId = value.toNumber();
      setModalText('Done! Your Pizza is ready');
      setCurrentStep(1);
      setMintingFinished(true);
      manualAddressCheck();
      setOvenStatus('');
    } catch (error) {
      console.log('Error my Error', error);
      setModalText('There was an error with your transaction!');
      setMintingFinished(true);
    }

    loadData();
    setCurrentRecipe([]);
    setCurrentRecipeName('');
    document.getElementById('nameInput').value = '';
  }

  function addIngredient(e, ingredient, i) {
    e.preventDefault();
    if (currentRecipe.includes(ingredientsCodes[ingredient])) {
      return;
    } else {
      setCurrentRecipe([...currentRecipe, ingredientsCodes[ingredient]]);
    }
  }

  function setCleanName(name) {
    const result = name.replace(/[^a-z ]/gi, '');
    setMessage(result);
    if (name.length <= 15 && name.length > 3) {
      setCurrentRecipeName(name.toLowerCase());
      setNameErrorLabel('');
    }
    if (name.length > 15) {
      setNameErrorLabel('Name Too Long!');
      setCurrentRecipeName('');
    }
    if (name.length < 3) {
      setNameErrorLabel('Name Too Short!');
      setCurrentRecipeName('');
    }
    if (name.length == 0) {
      setNameErrorLabel('No Recipe Name!');
      setCurrentRecipeName('');
    }
  }

  function checkPlusStatus() {
    manualAddressCheck();
  }

  return (
    <div
      style={{
        backgroundImage: `url(${background02})`,
        backgroundSize: '30%',
        backgroundColor: pageMode,
      }}
    >
      <Modal
        width="800px"
        title="About Luck and Pizza Status"
        visible={openLuckModal}
        style={{ fontFamily: 'Sniglet' }}
        closable={false}
        footer={[
          <Button type="primary" onClick={handleOkLuckModal}>
            close
          </Button>,
        ]}
      >
        <div>
          <h3 style={{ color: '#ff0278' }}>
            There are 5 posible Status you can get when Baking a Pizza:
          </h3>
          <Row>
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['overcooked']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Overcooked
              </p>
            </div>
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['undercooked']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Undercooked
              </p>
            </div>
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['good']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Good
              </p>
            </div>
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['great']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Great
              </p>
            </div>
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['perfect']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Perfect
              </p>
            </div>
          </Row>
          <h3 style={{ color: '#ff0278' }}>
            There are 2 types of Luck Boosts in Pizzapp:
          </h3>
          <Row>
            <p style={{ padding: 8 }}>Chef's Luck ✨</p>
            <p style={{ padding: 8 }}>Owner's Luck 🤞</p>
          </Row>
          <Divider />
          <h3 style={{ color: '#ff0278' }}>Baking with Owner's Luck 🤞</h3>

          <div
            style={{
              marginRight: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['good']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Good
              </p>
            </div>
            <p>= +2% Boost on your Chef's Luck ✨.</p>
          </div>
          <div
            style={{
              marginRight: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['great']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Great
              </p>
            </div>
            <p>= +4% Boost on your Chef's Luck ✨.</p>
          </div>
          <div
            style={{
              marginRight: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['perfect']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Perfect
              </p>
            </div>
            <p>
              = Golden Pizza! These Pizzas allow you to unlock one of Six
              Franchises.
            </p>
          </div>

          <p>
            Every time you send a Pizza to one of your Franchises, you get a
            permanent +1.2% Boost on your Owner's Luck 🤞
          </p>
          <Divider />
          <h3 style={{ color: '#ff0278' }}>Baking with Chef's Luck ✨</h3>
          <Row>
            <Col span={12}>
              <div
                style={{
                  marginRight: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img src={appIcons['good']} style={{ width: 64 }} />
                  <p
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Good
                  </p>
                </div>
                <p>= +6% Boost on your Chef's Luck ✨.</p>
              </div>
              <div
                style={{
                  marginRight: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img src={appIcons['great']} style={{ width: 64 }} />
                  <p
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Great
                  </p>
                </div>
                <p>= +8% Boost on your Chef's Luck ✨.</p>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  marginRight: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img src={appIcons['overcooked']} style={{ width: 64 }} />
                  <p
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Overcooked
                  </p>
                </div>
                <p>= Loose your accumulated Chef's Luck ✨.</p>
              </div>

              <div
                style={{
                  marginRight: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img src={appIcons['undercooked']} style={{ width: 64 }} />
                  <p
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Undercooked
                  </p>
                </div>
                <p>= Loose your accumulated Chef's Luck ✨.</p>
              </div>
            </Col>
          </Row>

          <div
            style={{
              marginRight: 10,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div style={{ marginRight: 10 }}>
              <img src={appIcons['perfect']} style={{ width: 64 }} />
              <p
                style={{
                  padding: 0,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                Perfect
              </p>
            </div>
            <p>
              = Golden Recipe! These recipes allow you to unlock one of Six
              Franchises.
            </p>
            <p>You will lose your accumulated Chef's Luck ✨.</p>
          </div>
        </div>
      </Modal>
      <Modal
        title="The Oven"
        visible={open}
        style={{ fontFamily: 'Sniglet' }}
        closable={false}
        footer={[
          mintingFinished == true ? (
            <div>
              {modalText == 'There was an error with your transaction!' ? (
                <p style={{ color: 'red' }}>{modalText}</p>
              ) : (
                <p>{modalText}</p>
              )}
              <Link
                style={{
                  padding: 10,
                  border: 'solid',
                  margin: 10,
                  borderRadius: 10,
                }}
                to="/mypizzas"
                type="primary"
              >
                👩‍🍳 Go to My Recipes!
              </Link>
            </div>
          ) : (
            'Please interact with your wallet when prompted 👉'
          ),
        ]}
      >
        {currentFunction == 'mint' ? (
          <div>
            <Lottie
              loop
              animationData={oven_anim}
              play
              style={{ width: 480 }}
            />
            <Steps direction="vertical" current={currentStep}>
              <Step
                title="In the Oven"
                description={
                  currentStep == 0 ? (
                    <Spin
                      tip="Your Pizza is in the Oven. Do not Close or Refresh the Page!"
                      indicator={antIcon}
                    />
                  ) : (
                    ''
                  )
                }
              />
              <Step
                title="Done!"
                description={
                  <div>
                    <p>Your Pizza is ready!</p>
                  </div>
                }
              />
            </Steps>
          </div>
        ) : (
          ''
        )}
        {currentFunction == 'mintWithPity' ? (
          <div>
            <Lottie
              loop
              animationData={oven_anim}
              play
              style={{ width: 480 }}
            />
            <Steps direction="vertical" current={currentStep}>
              <Step
                title="In the Oven"
                description={
                  currentStep == 0 ? (
                    <Spin
                      tip="Your Pizza is in the Oven. Do not Close or Refresh the Page!"
                      indicator={antIcon}
                    />
                  ) : (
                    ''
                  )
                }
              />
              <Step
                title="Done!"
                description={
                  <div>
                    <p>Your Pizza is ready!</p>
                  </div>
                }
              />
            </Steps>
          </div>
        ) : (
          ''
        )}
      </Modal>
      {kitchenStatus == '' ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            backgroundColor: pageMode,
          }}
        >
          {' '}
          <Spin
            tip="Checking Kitchen Status, Please Wait..."
            indicator={antIcon}
          />
        </div>
      ) : kitchenStatus == 'open' ? (
        <div className={pageMode == 'white' ? 'kitchen' : 'kitchen-black'}>
          <Row gutter={12}>
            <Col
              span={7}
              style={{
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <div
                style={{
                  backgroundColor: pageMode == 'white' ? 'white' : 'black',
                  borderRadius: '2em',
                  border: '3px solid transparent',
                  cursor: 'pointer',
                  boxShadow: '8px 8px 0px 4px #ff0278',
                  width: 340,
                  marginRight: 24,
                }}
              >
                <Row gutter={32}>
                  <Col>
                    {' '}
                    <img
                      style={{
                        display: 'block',
                        width: '50%',
                        margin: 'auto',
                        height: 'auto',
                        padding: 10,
                      }}
                      src={logo}
                      alt="logo"
                    />
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <div>
                        {currentRecipe.map((ing) => {
                          return (
                            <img
                              className="ingredient-layer-kitchen"
                              src={
                                ingredientsImages[ingredientsCodesReversed[ing]]
                              }
                            />
                          );
                        })}
                        <img
                          src={basePizzaKitchen}
                          style={{
                            filter:
                              pageMode == 'white'
                                ? 'drop-shadow(0px 0px 4px rgb(0 0 0 / 0.6))'
                                : 'drop-shadow(0px 0px 6px rgb(255 2 120 / 1))',
                            width: 400,
                          }}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        color: 'red',
                      }}
                    >
                      <h3 style={{ color: '#ff0278', paddingTop: 20 }}>
                        The Oven 🍕
                      </h3>
                      {ovenReady == true ? (
                        ''
                      ) : (
                        <p style={{ padding: 10 }}>
                          Your Oven is Off, Turn it On to Bake a Pizza!
                        </p>
                      )}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      {ovenStatus == `Turning On` ||
                      ovenStatus == 'Getting Hotter!' ? (
                        ''
                      ) : (
                        <button className="mint-btn" onClick={turnOvenOn}>
                          Turn On 🔥
                        </button>
                      )}
                      <button
                        className="mint-alt-btn"
                        onClick={checkPlusStatus}
                      >
                        Check 🔍
                      </button>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        color: '#ff0278',
                      }}
                    >
                      {ovenStatus == `Turning On` ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 10,
                          }}
                        >
                          <Spin indicator={antIcon} />
                          <p style={{ paddingLeft: 2 }}> 🔥Turning On...</p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        padding: 10,
                        justifyContent: 'center',
                        color: '#ff0278',
                      }}
                    >
                      {' '}
                      {ovenReady == true ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <Lottie
                            loop
                            animationData={ovenReady_anim}
                            play
                            style={{ width: 128 }}
                          />
                          <p style={{ fontSize: 'medium', color: '#44b675' }}>
                            {ovenStatus}
                          </p>
                        </div>
                      ) : (
                        ''
                      )}
                      {ovenStatus == `Getting Hotter!` ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <Lottie
                            loop
                            animationData={ovenReady_anim}
                            play
                            style={{ width: 128 }}
                          />
                          <Spin indicator={antIcon} />
                          <p style={{ fontSize: 'medium', color: '#44b675' }}>
                            {ovenStatus}
                          </p>
                          <p>{`Please Check 🔍 in... ${timeLeft}`}</p>
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    {wallet != '' &&
                    currentRecipeName != '' &&
                    currentRecipe.length != 0 &&
                    ovenReady == true &&
                    ovenStatus == 'Oven Ready to Bake ✔' ? (
                      <div
                        style={{
                          padding: 10,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                        }}
                      >
                        <button
                          onClick={createItemWithPity}
                          className="mint-btn"
                        >
                          Bake With Owners's Luck
                        </button>
                        {chefPity > 0 ? (
                          <button onClick={createItem} className="mint-alt-btn">
                            Bake With Chef's Luck
                          </button>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                    <Row gutter={8}>
                      <Col span={12}>
                        <h3 style={{ color: '#ff0278', padding: 10 }}>
                          📜Recipe Name
                        </h3>
                        <input
                          style={{ padding: 10, color: 'grey' }}
                          id="nameInput"
                          className="name-txt"
                          placeholder="Enter name..."
                          value={message}
                          onChange={(e) => setCleanName(e.target.value)}
                        />
                      </Col>
                      <Col span={12}>
                        {' '}
                        {currentRecipeName == '' ? (
                          <p className="ingredient-warning">{`${nameErrorLabel}`}</p>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                    <Row gutter={8}>
                      <Col span={12}>
                        <h3 style={{ color: '#ff0278', padding: 10 }}>
                          🧄 Ingredients
                        </h3>
                      </Col>
                      <Col span={12}>
                        {currentRecipe.length == 0 ? (
                          <p className="ingredient-warning">
                            Add more ingredients!
                          </p>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                    <Row gutter={16} style={{ paddingLeft: 20 }}>
                      {currentRecipe.map((ingredient) => (
                        <button
                          className="recipe-item"
                          onClick={(e) => {
                            setCurrentRecipe(
                              currentRecipe.filter((i) => i !== ingredient)
                            );
                          }}
                        >
                          <p className="delete-btn">X</p>
                          <p>
                            {ingredientsCodesReversed[ingredient]
                              .charAt(0)
                              .toUpperCase() +
                              ingredientsCodesReversed[ingredient]
                                .slice(1)
                                .split('_')
                                .join(' ')}
                          </p>
                        </button>
                      ))}
                    </Row>
                  </Col>
                </Row>
                <Divider />
                {wallet == '' ? (
                  <p className="wallet-warning">
                    Connect your walllet to Mint!
                  </p>
                ) : (
                  <p className="wallet-success">Wallet Connected!</p>
                )}
              </div>
            </Col>
            <Col span={17} style={{ backgroundColor: 'none' }}>
              <Row gutter={12}>
                <Col span={6}>
                  <Card
                    bordered={false}
                    style={{
                      marginTop: 10,
                      borderRadius: 10,
                      backgroundColor: '#444444',
                      color: 'white',
                      boxShadow: '0px 0px 0px -9px rgba(0,0,0,0.6)',
                    }}
                    headStyle={{
                      color: 'white',
                      borderTopRightRadius: 10,
                      borderTopLeftRadius: 10,
                      border: 'none',
                    }}
                  >
                    <Meta
                      style={{
                        justifyContent: 'center',
                        opacity: 100,
                        color: 'white',
                      }}
                      title={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'basline',
                          }}
                        >
                          {' '}
                          <h4
                            style={{
                              marginRight: 5,
                              color: '#00bdff',
                              paddingTop: 8,
                            }}
                          >
                            Baking Price:
                          </h4>
                          <h4
                            style={{
                              marginRight: 5,
                              color: 'white',
                              paddingTop: 8,
                            }}
                          >
                            0.04 BNB
                          </h4>{' '}
                        </div>
                      }
                    />
                  </Card>
                </Col>
                <Col span={18}>
                  {' '}
                  <Card
                    className="top-luck"
                    bordered={false}
                    style={{
                      marginTop: 10,
                      borderRadius: 10,
                      backgroundColor: '#444444',
                      color: 'white',
                      boxShadow: '0px 0px 0px -9px rgba(0,0,0,0.6)',
                    }}
                    headStyle={{
                      color: 'white',
                      borderTopRightRadius: 10,
                      borderTopLeftRadius: 10,
                      border: 'none',
                    }}
                  >
                    <Meta
                      style={{
                        justifyContent: 'center',
                        opacity: 100,
                        color: 'white',
                      }}
                      title={
                        <div className="chef-details">
                          <p>{`👨‍🍳Chef: ${
                            currentWallet.slice(0, 16) + '...'
                          }`}</p>
                          <p style={{ paddingLeft: 10 }}>
                            {chefPity == 'loading' ? (
                              <Spin
                                tip="Loading ✨Chef's Luck"
                                indicator={antIcon}
                              />
                            ) : (
                              `✨Chef's Luck: +${chefPity / 10}% `
                            )}
                          </p>
                          <p style={{ paddingLeft: 10 }}>
                            {franchisePity == 'loading' ? (
                              <Spin
                                tip="Loading 🤞Owner's Luck: +0%"
                                indicator={antIcon}
                              />
                            ) : (
                              `🤞Owner's Luck: +${franchisePity / 10}%`
                            )}
                          </p>
                        </div>
                      }
                    />
                  </Card>
                </Col>
              </Row>

              <div
                bordered={false}
                style={{
                  borderRadius: 10,
                  background: 'none',
                  backgroundColor: 'none',
                  margin: 0,
                  padding: 0,
                }}
              >
                <Row>
                  {ingredients != '' &&
                    ingredients?.map((ingredient, i) => (
                      <div
                        className={
                          pageMode == 'white'
                            ? 'border-image'
                            : 'border-image-black'
                        }
                        onClick={(e) => {
                          addIngredient(e, ingredient, i);
                        }}
                      >
                        <Col
                          style={{
                            justifyContent: 'center',
                          }}
                        >
                          <Row
                            style={{
                              justifyContent: 'center',
                              color: '#ff0278',
                              fontSize: 'medium',
                            }}
                          >
                            {ingredient.charAt(0).toUpperCase() +
                              ingredient.slice(1).split('_').join(' ')}
                          </Row>

                          <img
                            src={ingredientsKitchen[ingredient]}
                            className="center"
                          />
                        </Col>
                      </div>
                    ))}
                </Row>

                <div
                  className={
                    pageMode == 'white' ? 'border-howto' : 'border-howto-black'
                  }
                >
                  <h3
                    style={{
                      color: '#ff0278',
                      marginTop: 20,
                      marginBottom: 20,
                      marginLeft: 10,
                    }}
                  >
                    👩‍🍳 How To Make a Pizza!
                  </h3>
                  <div
                    style={{ fontFamily: 'Sniglet', backgroundColor: 'none' }}
                  >
                    <Row gutter={12}>
                      <Col span={5}>
                        <Row>
                          <p
                            style={{
                              background: '#ff0278',
                              borderRadius: '50%',
                              paddingTop: 2,
                              paddingBottom: 2,
                              paddingLeft: 12,
                              paddingRight: 12,
                              color: 'white',
                              marginLeft: 10,
                            }}
                          >
                            1
                          </p>
                          <p
                            style={{
                              paddingTop: 2,
                              paddingLeft: 10,
                              color: pageMode == 'white' ? 'black' : 'white',
                            }}
                          >
                            Turn on The Oven 🍕
                          </p>
                        </Row>
                      </Col>
                      <Col span={6}>
                        {' '}
                        <Row>
                          <p
                            style={{
                              background: '#ff0278',
                              borderRadius: '50%',
                              paddingTop: 2,
                              paddingBottom: 2,
                              paddingLeft: 10,
                              paddingRight: 10,
                              color: 'white',
                              marginLeft: 10,
                            }}
                          >
                            2
                          </p>
                          <p
                            style={{
                              paddingTop: 2,
                              paddingLeft: 10,
                              color: pageMode == 'white' ? 'black' : 'white',
                            }}
                          >
                            Pick Your Ingredients 🧄
                          </p>
                        </Row>
                      </Col>
                      <Col span={5}>
                        {' '}
                        <Row>
                          <p
                            style={{
                              background: '#ff0278',
                              borderRadius: '50%',
                              paddingTop: 2,
                              paddingBottom: 2,
                              paddingLeft: 10,
                              paddingRight: 10,
                              color: 'white',
                              marginLeft: 10,
                            }}
                          >
                            3
                          </p>
                          <p
                            style={{
                              paddingTop: 2,
                              paddingLeft: 10,
                              color: pageMode == 'white' ? 'black' : 'white',
                            }}
                          >
                            Name your Recipe 📜
                          </p>
                        </Row>
                      </Col>
                      <Col span={5}>
                        {' '}
                        <Row>
                          <p
                            style={{
                              background: '#ff0278',
                              borderRadius: '50%',
                              paddingTop: 2,
                              paddingBottom: 2,
                              paddingLeft: 10,
                              paddingRight: 10,
                              color: 'white',
                              marginLeft: 10,
                            }}
                          >
                            4
                          </p>
                          <p
                            style={{
                              paddingTop: 2,
                              paddingLeft: 10,
                              color: pageMode == 'white' ? 'black' : 'white',
                            }}
                          >
                            Check 🔍 The Oven
                          </p>
                        </Row>
                      </Col>
                      <Col span={3}>
                        {' '}
                        <Row>
                          <p
                            style={{
                              background: '#ff0278',
                              borderRadius: '50%',
                              paddingTop: 2,
                              paddingBottom: 2,
                              paddingLeft: 10,
                              paddingRight: 10,
                              color: 'white',
                              marginLeft: 10,
                            }}
                          >
                            5
                          </p>
                          <p
                            style={{
                              paddingTop: 2,
                              paddingLeft: 10,
                              color: pageMode == 'white' ? 'black' : 'white',
                            }}
                          >
                            Bake it!
                          </p>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <button className="luck-btn" onClick={showLuckModal}>
                        How Luck Works?
                      </button>

                      <p
                        style={{
                          padding: 10,
                          color: 'gray',
                          fontSize: 'small',
                          fontFamily: 'sans-serif',
                        }}
                      >
                        In order to guarantee fairness and randomness in the
                        Baking(minting) process, When You Turn On the Oven 🍕,
                        you are generating a random number through an Oracle 🧙‍♀️;
                        this may take some time. Please be patient and use the
                        Check 🔍 button to see if the number has been generated.
                        Happy Baking!
                      </p>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            padding: 10,
            justifyContent: 'center',
            background: pageMode,
            borderRadius: 10,
            margin: 10,
          }}
        >
          <h3>🕑 Kitchen Closed! Come back later...</h3>
        </div>
      )}
    </div>
  );
};

export default Kitchen;
