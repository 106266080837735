import React from 'react';
import './footer.css';
import 'antd/dist/antd.css';

const Footer = ({ pageMode }) => {
  return (
    <div className={pageMode == 'white' ? 'footer' : 'footer-black'}></div>
  );
};

export default Footer;
