import React from 'react';
import 'antd/dist/antd.css';
import './about.css';
import { Card, Row, Col, Badge, Avatar, Divider } from 'antd';
import logo from '../imgs/LogoV1.svg';
import F_CheesyRockets from '../imgs/F_CheesyRockets.png';
import F_Ludos from '../imgs/F_Ludos.png';
import F_MeltingMoon from '../imgs/F_MeltingMoon.png';
import F_PizzApp from '../imgs/F_PizzApp.png';
import F_PizzaXpress from '../imgs/F_PizzaXpress.png';
import F_SliceOfHeaven from '../imgs/F_SliceOfHeaven.png';

import franchise_logo_01 from '../imgs/franchise_logo_01.png';
import franchise_logo_02 from '../imgs/franchise_logo_02.png';
import franchise_logo_03 from '../imgs/franchise_logo_03.png';
import franchise_logo_04 from '../imgs/franchise_logo_04.png';
import franchise_logo_05 from '../imgs/franchise_logo_05.png';
import franchise_logo_06 from '../imgs/franchise_logo_06.png';
import background02 from '../imgs/background02.svg';
import Bunny from '../imgs/Bunny.png';
import logo_r from '../imgs/logo_r.png';
import { UserOutlined } from '@ant-design/icons';

const About = ({ pageMode, appIcons, franchises }) => {
  const { Meta } = Card;
  const franchisesData = [
    {
      title: franchises.at(0).name,
      img: franchise_logo_01,
      id: 0,
      franchiseImg: F_Ludos,
    },
    {
      title: franchises.at(1).name,
      img: franchise_logo_02,
      id: 1,
      franchiseImg: F_CheesyRockets,
    },
    {
      title: franchises.at(2).name,
      img: franchise_logo_03,
      id: 2,
      franchiseImg: F_PizzaXpress,
    },
    {
      title: franchises.at(3).name,
      img: franchise_logo_04,
      id: 3,
      franchiseImg: F_MeltingMoon,
    },
    {
      title: franchises.at(4).name,
      img: franchise_logo_05,
      id: 4,
      franchiseImg: F_SliceOfHeaven,
    },
    {
      title: franchises.at(5).name,
      img: franchise_logo_06,
      id: 5,
      franchiseImg: F_PizzApp,
    },
  ];
  return (
    <div
      className={pageMode == 'white' ? 'about' : 'about-black'}
      style={{
        backgroundImage: `url(${background02})`,
        backgroundSize: '30%',
      }}
    >
      <div>
        <Row>
          <Card
            title="About The Game"
            bordered={false}
            headStyle={{
              backgroundColor: '#ff0278',
              color: 'white',
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
            }}
            style={{
              borderRadius: 10,
              margin: 10,

              boxShadow: '6px 6px 0px 6px #ff0278',
            }}
          >
            <div>
              <h3
                style={{
                  color: '#ff0278',
                  marginTop: 20,
                  marginBottom: 20,
                  marginLeft: 10,
                }}
              >
                👩‍🍳 How To Make a Pizza!
              </h3>
              <div style={{ fontFamily: 'Sniglet', backgroundColor: 'none' }}>
                <Row gutter={12}>
                  <Col span={5}>
                    <Row>
                      <p
                        style={{
                          background: '#ff0278',
                          borderRadius: '50%',
                          paddingTop: 2,
                          paddingBottom: 2,
                          paddingLeft: 12,
                          paddingRight: 12,
                          color: 'white',
                          marginLeft: 10,
                        }}
                      >
                        1
                      </p>
                      <p
                        style={{
                          paddingTop: 2,
                          paddingLeft: 10,
                          color: 'black',
                        }}
                      >
                        Turn on The Oven 🍕
                      </p>
                    </Row>
                  </Col>
                  <Col span={6}>
                    {' '}
                    <Row>
                      <p
                        style={{
                          background: '#ff0278',
                          borderRadius: '50%',
                          paddingTop: 2,
                          paddingBottom: 2,
                          paddingLeft: 10,
                          paddingRight: 10,
                          color: 'white',
                          marginLeft: 10,
                        }}
                      >
                        2
                      </p>
                      <p
                        style={{
                          paddingTop: 2,
                          paddingLeft: 10,
                          color: 'black',
                        }}
                      >
                        Pick Your Ingredients 🧄
                      </p>
                    </Row>
                  </Col>
                  <Col span={5}>
                    {' '}
                    <Row>
                      <p
                        style={{
                          background: '#ff0278',
                          borderRadius: '50%',
                          paddingTop: 2,
                          paddingBottom: 2,
                          paddingLeft: 10,
                          paddingRight: 10,
                          color: 'white',
                          marginLeft: 10,
                        }}
                      >
                        3
                      </p>
                      <p
                        style={{
                          paddingTop: 2,
                          paddingLeft: 10,
                          color: 'black',
                        }}
                      >
                        Name your Recipe 📜
                      </p>
                    </Row>
                  </Col>
                  <Col span={5}>
                    {' '}
                    <Row>
                      <p
                        style={{
                          background: '#ff0278',
                          borderRadius: '50%',
                          paddingTop: 2,
                          paddingBottom: 2,
                          paddingLeft: 10,
                          paddingRight: 10,
                          color: 'white',
                          marginLeft: 10,
                        }}
                      >
                        4
                      </p>
                      <p
                        style={{
                          paddingTop: 2,
                          paddingLeft: 10,
                          color: 'black',
                        }}
                      >
                        Check 🔍 The Oven
                      </p>
                    </Row>
                  </Col>
                  <Col span={3}>
                    {' '}
                    <Row>
                      <p
                        style={{
                          background: '#ff0278',
                          borderRadius: '50%',
                          paddingTop: 2,
                          paddingBottom: 2,
                          paddingLeft: 10,
                          paddingRight: 10,
                          color: 'white',
                          marginLeft: 10,
                        }}
                      >
                        5
                      </p>
                      <p
                        style={{
                          paddingTop: 2,
                          paddingLeft: 10,
                          color: 'black',
                        }}
                      >
                        Bake it!
                      </p>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>{' '}
            <Divider />
            <h3 style={{ color: '#ff0278' }}>
              There are 5 posible Status you can get when Baking a Pizza:
            </h3>
            <Row>
              <Col span={12}>
                <Row>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['overcooked']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Overcooked
                    </p>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['undercooked']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Undercooked
                    </p>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['good']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Good
                    </p>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['great']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Great
                    </p>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <img src={appIcons['perfect']} style={{ width: 64 }} />
                    <p
                      style={{
                        padding: 0,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Perfect
                    </p>
                  </div>
                </Row>
              </Col>
              <Col span={12}>
                {' '}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    src={appIcons['perfect']}
                    style={{ width: 64, paddingBottom: 20 }}
                  />
                  <p style={{ paddingTop: 20 }}>
                    If you Bake a Perfect Pizza, you can use it to Unlock one of
                    the Six Franchsises.
                  </p>
                </div>
              </Col>
            </Row>
            <div>
              {' '}
              <Divider />
              <h3 style={{ color: '#ff0278' }}>
                There are 2 types of Luck Boosts in Pizzapp:
              </h3>
              <Row>
                <p style={{ padding: 8 }}>Chef's Luck ✨</p>
                <p style={{ padding: 8 }}>Owner's Luck 🤞</p>
              </Row>
              <Divider />
              <h3 style={{ color: '#ff0278' }}>Baking with Owner's Luck 🤞</h3>
              <div
                style={{
                  marginRight: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img src={appIcons['good']} style={{ width: 64 }} />
                  <p
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Good
                  </p>
                </div>
                <p>= +2% Boost on your Chef's Luck ✨.</p>
              </div>
              <div
                style={{
                  marginRight: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img src={appIcons['great']} style={{ width: 64 }} />
                  <p
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Great
                  </p>
                </div>
                <p>= +4% Boost on your Chef's Luck ✨.</p>
              </div>
              <div
                style={{
                  marginRight: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img src={appIcons['perfect']} style={{ width: 64 }} />
                  <p
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Perfect
                  </p>
                </div>
                <p>
                  = Golden Pizza! These Pizzas allow you to unlock one of Six
                  Franchises.
                </p>
              </div>
              <p>
                Every time you send a Pizza to one of your Franchises, you get a
                permanent +1.2% Boost on your Owner's Luck 🤞
              </p>
              <Divider />
              <h3 style={{ color: '#ff0278' }}>Baking with Chef's Luck ✨</h3>
              <Row>
                <Col span={12}>
                  <div
                    style={{
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ marginRight: 10 }}>
                      <img src={appIcons['good']} style={{ width: 64 }} />
                      <p
                        style={{
                          padding: 0,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        Good
                      </p>
                    </div>
                    <p>= +6% Boost on your Chef's Luck ✨.</p>
                  </div>
                  <div
                    style={{
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ marginRight: 10 }}>
                      <img src={appIcons['great']} style={{ width: 64 }} />
                      <p
                        style={{
                          padding: 0,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        Great
                      </p>
                    </div>
                    <p>= +8% Boost on your Chef's Luck ✨.</p>
                  </div>
                </Col>
                <Col span={12}>
                  <div
                    style={{
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ marginRight: 10 }}>
                      <img src={appIcons['overcooked']} style={{ width: 64 }} />
                      <p
                        style={{
                          padding: 0,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        Overcooked
                      </p>
                    </div>
                    <p>= Loose your acumulated Chef's Luck ✨.</p>
                  </div>

                  <div
                    style={{
                      marginRight: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ marginRight: 10 }}>
                      <img
                        src={appIcons['undercooked']}
                        style={{ width: 64 }}
                      />
                      <p
                        style={{
                          padding: 0,
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        Undercooked
                      </p>
                    </div>
                    <p>= Loose your acumulated Chef's Luck ✨.</p>
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  marginRight: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <div style={{ marginRight: 10 }}>
                  <img src={appIcons['perfect']} style={{ width: 64 }} />
                  <p
                    style={{
                      padding: 0,
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Perfect
                  </p>
                </div>
                <p>
                  = Golden Recipe! These recipes allow you to unlock one of Six
                  Franchises.
                </p>
                <p>You will loose your acumulated Chef's Luck ✨.</p>
              </div>
            </div>{' '}
            <Divider />
            <h3 style={{ color: '#ff0278' }}>The Franchsies:</h3>
            <div style={{ display: 'flex' }}>
              {' '}
              {franchisesData.map((data) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 12,

                    alignItems: 'center',
                  }}
                >
                  <img src={data.img} style={{ width: 128 }} />

                  <p>{`${data.title}`}</p>
                </div>
              ))}
            </div>
            <p>
              You can Send up to 5 Pizzas to each Franchise, each Pizza gives
              you a permanent +1.2% Boost on your 🤞Owner's Luck.
            </p>
            <Badge color="green" count={`🍕 2`}>
              {' '}
              <img src={franchisesData.at(4).img} style={{ width: 128 }} />
            </Badge>
            <p>
              Once you sent 5 Pizzas to an Unlocked Franchise, you can Claim the
              Machine for that Franchise.
            </p>
            <Badge
              color="red"
              count={<button className="claim-btn">Claim!</button>}
            >
              {' '}
              <img src={franchisesData.at(4).img} style={{ width: 128 }} />
            </Badge>{' '}
            <p>Hurry up! there only 500 Machines available!</p>
            <Divider />
            <p style={{ fontWeight: 'bold' }}>IMPORTANT!</p>
            <p>
              This Machine NFTs contain on chain metadata, and are intended to
              be used in the Pizzapp and Future related Projects. They are not
              ment to be traded on other secondary markets, if you do so, you
              may loose them forever!
            </p>
          </Card>
        </Row>
        <Card
          title="Security"
          bordered={false}
          headStyle={{
            backgroundColor: '#ff0278',
            color: 'white',
            borderTopLeftRadius: 10,
            borderTopRightRadius: 10,
          }}
          style={{
            borderRadius: 10,
            margin: 10,
            boxShadow: '6px 6px 0px 6px #ff0278',
          }}
        >
          <p style={{ padding: 5, color: 'black' }}>
            🐦 Official Twitter Account: @PizzApp_BNB
          </p>{' '}
          <p style={{ padding: 5, color: 'black' }}>
            🌍 Official Site: pizzapp.site
          </p>{' '}
          <p style={{ padding: 5, color: 'black' }}>
            🚫 There are no Discord Servers, Telegram Channels, or any other
            method of official communication.
          </p>{' '}
          <p style={{ padding: 5, color: 'black' }}>
            Members of PizzApp will never contact you. There are no Support
            staff or moderators. Beware of Scammers.
          </p>{' '}
          <p style={{ padding: 5, color: 'black' }}>
            🙋‍♀️ If you have any inquiries or technical problems, please DM the
            official Twitter Account.
          </p>{' '}
        </Card>
      </div>

      <Card
        title="The Team"
        bordered={false}
        headStyle={{
          backgroundColor: '#ff0278',
          color: 'white',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
        style={{
          borderRadius: 10,
          margin: 10,
          boxShadow: '6px 6px 0px 6px #ff0278',
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Row gutter={12}>
            <Col span={8}>
              <Avatar size={128} src={Bunny}></Avatar>
              <h3
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 10,
                }}
              >
                Hex Vanilla
              </h3>
              <p
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: 'gray',
                }}
              >
                @hex_vanilla
              </p>
            </Col>
            <Col span={8}>
              <Avatar size={128} src={logo_r}></Avatar>
              <p
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 10,
                }}
              >
                Pixel Index
              </p>
              <p
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: 'gray',
                }}
              >
                @rodrigo_saffie
              </p>
            </Col>
            <Col span={8}>
              <Avatar size={128} icon={<UserOutlined />}></Avatar>
              <p
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  paddingTop: 10,
                }}
              >
                Dipok
              </p>
              <p
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  color: 'gray',
                }}
              >
                @pablodip1
              </p>
            </Col>
          </Row>
        </div>
        <p style={{ padding: 5, color: 'black' }}>
          Pizzapp is an experimental web3 project developed by a small group of
          artists. Happy Baking!
        </p>{' '}
        <p style={{ padding: 5, color: 'gray' }}>
          If you have any inquiries or technical problems, please DM the
          official Twitter Account @PizzApp_BNB
        </p>{' '}
      </Card>
    </div>
  );
};

export default About;
