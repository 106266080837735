import { useState, useEffect } from 'react';
import { Card, Row, Col, Button } from 'antd';
import './adminpage.css';
import 'antd/dist/antd.css';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';

import { nftaddress, pizzappmarketaddress, franchiseaddress } from '../config';

import NFT from '../_artifacts/NFT.sol/NFT.json';
import PizzappMarket from '../_artifacts/PizzappMarket.sol/PizzappMarket.json';
import Franchise from '../_artifacts/Franchise.sol/Franchise.json';

const AdminPage = ({
  mintedItems,
  marketItems,
  getBalance,
  loadData,
  loadingState,
  providerSwitch,
}) => {
  const { Meta } = Card;
  //const { marketItems, transactionsData, mintedItemsData } = data;

  const [balance, setBalance] = useState('');
  const [sold, setSold] = useState('');

  const [fees, setFees] = useState('');

  const [withdrawAddress, setwithdrawAddress] = useState('');

  const [kitchenOpen, setKitchenOpen] = useState('');

  const [franchiseMinter, setFranchiseMinter] = useState('');
  const [marketMinter, setMarketMinter] = useState('');

  const [minterAddress, setMinterAddress] = useState('');

  useEffect(() => {
    //getTrasanctions();
    loadData();
  }, []);

  useEffect(() => {
    if (loadingState == 'Loaded') {
      console.log('loading data');
    }
  }, [loadingState]);

  useEffect(() => {
    console.log('withdrawAddress', withdrawAddress);
  }, [withdrawAddress]);

  async function getBalance() {
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);

    const balanceData = await tokenContract.getBalance();
    const stringData = balanceData.toString();
    console.log(stringData, balanceData);

    return stringData;
  }

  async function getSales() {
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const pizzaMarketContract = new ethers.Contract(
      pizzappmarketaddress,
      PizzappMarket.abi,
      provider
    );

    const balanceData = await pizzaMarketContract.getTokensSold();
    const numberData = balanceData.toNumber();

    return numberData;
  }

  async function isKitchenOpen() {
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, provider);

    const kitchenStatus = await tokenContract.getKitchenStatus();

    console.log('kitchenStatus', kitchenStatus);

    return kitchenStatus;
  }

  async function switchKitchenStatus() {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(nftaddress, NFT.abi, signer);

    const transaction = await tokenContract.setKitchenStatus();

    await transaction.wait();
  }

  async function withdraw(e) {
    //change to auto withdraw
    e.preventDefault();
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(nftaddress, NFT.abi, signer);

    const transaction = await contract.withdraw(withdrawAddress);

    await transaction.wait();
  }

  async function getBalancePressed() {
    setBalance(await getBalance());
  }

  async function getSalesPressed() {
    setSold(await getSales());
  }

  async function isKitchenOpenPressed() {
    if ((await isKitchenOpen()) == true) setKitchenOpen('Kitchen Open');
    if ((await isKitchenOpen()) == false) setKitchenOpen('Kitchen Closed');
  }

  async function setMinterAddressForFranchise() {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      franchiseaddress,
      Franchise.abi,
      signer
    );

    const transaction = await contract.setNftContractAddress(minterAddress);

    await transaction.wait();
    console.log('Minter Franchise', transaction);
  }

  async function setMinterAddressForMarket() {
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      pizzappmarketaddress,
      PizzappMarket.abi,
      signer
    );

    const transaction = await contract.setNftContractAddress(minterAddress);

    await transaction.wait();
    console.log('Minter Market', transaction);
  }

  async function isFranchiseSet() {
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const contract = new ethers.Contract(
      franchiseaddress,
      Franchise.abi,
      provider
    );

    const response = await contract.fetchIsContractSet();

    return response;
  }

  async function isMarketSet() {
    const provider = new ethers.providers.JsonRpcProvider(providerSwitch);
    const contract = new ethers.Contract(
      pizzappmarketaddress,
      PizzappMarket.abi,
      provider
    );

    const response = await contract.fetchIsContractSet();

    return response;
  }

  async function isFranchiseSetPressed() {
    if ((await isFranchiseSet()) == true) setFranchiseMinter('Ok');
    if ((await isFranchiseSet()) == false) setFranchiseMinter('not ready');
  }

  async function isMarketSetSetPressed() {
    if ((await isMarketSet()) == true) setMarketMinter('Ok');
    if ((await isMarketSet()) == false) setMarketMinter('not ready');
  }

  return (
    <div className="market">
      <Row>
        <Col>
          <Card
            bordered={false}
            style={{
              margin: 10,
              borderRadius: 10,
            }}
          >
            <Meta
              title="Panel"
              description=""
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: 10,
              }}
            ></Meta>

            <Row>
              <Col span={6}>
                <h3>
                  {balance != ''
                    ? `Balance: ${ethers.utils.formatUnits(
                        balance,
                        'ether'
                      )} BNB`
                    : ''}
                </h3>
                <input
                  type="text"
                  onChange={(e) => setwithdrawAddress(e.target.value)}
                />
                <p>{withdrawAddress}</p>
                <Button onClick={(e) => withdraw(e)}>Withdraw</Button>
                <Button onClick={getBalancePressed}>Get Balance</Button>
              </Col>
              <Col span={10}>
                <Row>
                  {' '}
                  <Col span={12}>
                    {' '}
                    <p>{franchiseMinter}</p>
                    <Button onClick={isFranchiseSetPressed}>
                      is Franchise Minter ready?
                    </Button>
                    <p>{marketMinter}</p>
                    <Button onClick={isMarketSetSetPressed}>
                      is Market Minter ready?
                    </Button>
                  </Col>
                  <Col span={12}>
                    {' '}
                    <input
                      type="text"
                      onChange={(e) => setMinterAddress(e.target.value)}
                    />
                    {minterAddress.toLowerCase() == nftaddress.toLowerCase() ? (
                      <p style={{ color: 'green' }}>{minterAddress}</p>
                    ) : (
                      <p style={{ color: 'red' }}>{minterAddress}</p>
                    )}
                    <Button onClick={setMinterAddressForFranchise}>
                      Set Mint Address for Franchise
                    </Button>
                    <input
                      type="text"
                      onChange={(e) => setMinterAddress(e.target.value)}
                    />
                    {minterAddress.toLowerCase() == nftaddress.toLowerCase() ? (
                      <p style={{ color: 'green' }}>{minterAddress}</p>
                    ) : (
                      <p style={{ color: 'red' }}>{minterAddress}</p>
                    )}
                    <Button onClick={setMinterAddressForMarket}>
                      Set Mint Address for Market
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col span={8}>
                {' '}
                <p>{kitchenOpen}</p>
                <Button onClick={isKitchenOpenPressed}>is Ktichen Open?</Button>
                <Button onClick={switchKitchenStatus}>
                  Open/Close Kitchen
                </Button>
                <Button onClick={getSalesPressed}>Get Sales</Button>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col>
          <Card
            bordered={false}
            style={{
              margin: 10,
              borderRadius: 10,
            }}
          >
            <Meta
              title="Sales"
              description={`Sold: ${sold}`}
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin: 10,
              }}
            ></Meta>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AdminPage;
