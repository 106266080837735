import { useEffect, useState } from 'react';
import { Card, Row, Col, Button, Modal, Steps, Spin } from 'antd';
import './mypizzas.css';
import 'antd/dist/antd.css';
import { nftaddress, pizzappmarketaddress } from '../config';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';

import NFT from '../_artifacts/NFT.sol/NFT.json';
import PizzappMarket from '../_artifacts/PizzappMarket.sol/PizzappMarket.json';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import background02 from '../imgs/background02.svg';

const MyPizzas = ({
  currentWallet,
  mintedItems,
  marketItems,
  loadData,
  ingredientsImages,
  pizzaStatus,
  ingredientsIcons,
  pageMode,
  ingredientsCodesReversed,
  boughtItems,
  appIcons,
}) => {
  const { Meta } = Card;
  const [walletAddress, setWalletAddress] = useState('');
  const [view, setView] = useState('Not Listed');

  const [sellingPrice, setSellingPrice] = useState('');

  const [open, setOpen] = useState(false);
  const [listingFinished, setlistingFinished] = useState(false);
  const [transferFinished, settransferFinished] = useState(false);
  const [delistingFinished, setdelistingFinished] = useState(false);
  const [modalText, setModalText] = useState('');
  const [selectedItem, setSelectedItem] = useState('');

  const [currentStep, setCurrentStep] = useState('');
  const [currentFunction, setCurrentFunction] = useState('');

  const { Step } = Steps;
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadData();
    setWalletAddress(currentWallet);
  }, [currentWallet]);

  function switchView(e) {
    e.preventDefault();
    if (view == 'For Sale') {
      setView('Not Listed');
    }
    if (view == 'Not Listed') {
      setView('For Sale');
    }
  }

  //list on market
  async function listOnMarket(tokenId) {
    setModalText('Listing your Recipe on the Market, please wait!');
    setCurrentStep(1);
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const price = ethers.utils.parseUnits(sellingPrice, 'ether');

    let contract = new ethers.Contract(
      pizzappmarketaddress,
      PizzappMarket.abi,
      signer
    );
    try {
      let transaction = await contract.listMarketItem(tokenId, price); //nftaddress
      await transaction.wait();
      setModalText('Recipe Successfully Listed on the Market!');
      setCurrentStep(2);
      setlistingFinished(true);
    } catch (error) {
      console.log('Error Listing on Market', error);
      setModalText('Error Listing on Market');
      setlistingFinished(true);
    }

    loadData();
  }

  //delist from market
  async function delistItem(e, tokenId) {
    setlistingFinished(false);
    setdelistingFinished(false);
    settransferFinished(false);

    setModalText('Delisting your Recipe from the Market, please wait!');
    setCurrentFunction('delisting');
    setCurrentStep(0);
    showModal();

    e.preventDefault();
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    let contract = new ethers.Contract(
      pizzappmarketaddress,
      PizzappMarket.abi,
      signer
    );
    try {
      let transaction = await contract.delistMarketItem(tokenId); //nftaddress
      await transaction.wait();
      setModalText('Recipe Successfully Delisted from the Market');
      setCurrentStep(1);
      setdelistingFinished(true);
    } catch (error) {
      console.log('Error on Delisting NFT', error);
      setModalText('Error on Delisting NFT');
      setdelistingFinished(true);
    }

    loadData();
  }

  //approval for re listing on market
  async function approval(e, tokenId) {
    setlistingFinished(false);
    setdelistingFinished(false);
    settransferFinished(false);

    setModalText('Listing your Recipe on the Market, please wait!');
    setCurrentFunction('listing');
    setCurrentStep(0);
    showModal();

    e.preventDefault();
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();

    let contract = new ethers.Contract(nftaddress, NFT.abi, signer);
    try {
      let transaction = await contract.listApproval(tokenId);
      await transaction.wait();
      listOnMarket(tokenId);
    } catch (error) {
      console.log('Error on Listing Approval NFT', error);
      setModalText('Error on Listing Approval NFT');
      setlistingFinished(true);
    }
  }

  return (
    <div
      className={pageMode == 'white' ? 'main' : 'main-black'}
      style={{
        backgroundImage: `url(${background02})`,
        backgroundSize: '30%',
      }}
    >
      <Modal
        title="My Recipes"
        style={{ fontFamily: 'Sniglet' }}
        visible={open}
        footer={[
          listingFinished == true ||
          delistingFinished == true ||
          transferFinished == true ? (
            <div>
              {' '}
              <p>{modalText}</p>
              <Button type="primary" onClick={handleOk}>
                Close
              </Button>
            </div>
          ) : (
            'Please interact with your wallet when prompted 👉'
          ),
        ]}
      >
        {currentFunction == 'listing' ? (
          <Steps direction="vertical" current={currentStep}>
            <Step
              title="Approval"
              description={
                currentStep == 0 ? (
                  <Spin
                    tip="Approving Contract Interaction, Please Wait!"
                    indicator={antIcon}
                  />
                ) : (
                  ''
                )
              }
            />

            <Step
              title="Listing"
              description={
                currentStep == 1 ? (
                  <Spin
                    tip="Listing your Recipe on the Market, please wait!"
                    indicator={antIcon}
                  />
                ) : (
                  ''
                )
              }
            />
            <Step
              title="Done!"
              description="Recipe Successfully Listed on the Market!"
            />
          </Steps>
        ) : (
          ''
        )}
        {currentFunction == 'trasnfering' ? (
          <Steps direction="vertical" current={currentStep}>
            <Step
              title="Approval"
              description={
                currentStep == 0 ? (
                  <Spin
                    tip="Approving Contract Interaction, Please Wait!"
                    indicator={antIcon}
                  />
                ) : (
                  ''
                )
              }
            />
            <Step
              title="Trasnfering"
              description={
                currentStep == 1 ? (
                  <Spin
                    tip="Transfering Recipe, Please Wait!"
                    indicator={antIcon}
                  />
                ) : (
                  ''
                )
              }
            />
            <Step title="Done!" description="Recipe Successfully Transfered!" />
          </Steps>
        ) : (
          ''
        )}
        {currentFunction == 'delisting' ? (
          <Steps direction="vertical" current={currentStep}>
            <Step
              title="Delisting"
              description={
                currentStep == 0 ? (
                  <Spin
                    tip="Delisting your Recipe from the Market, please wait!"
                    indicator={antIcon}
                  />
                ) : (
                  ''
                )
              }
            />
            <Step
              title="Done!"
              description="Recipe Successfully Delisted from the Market"
            />
          </Steps>
        ) : (
          ''
        )}
      </Modal>
      <Row>
        <Col span={12}>
          <Card
            title={
              <div>
                Chef's Creations<p></p>
                <p>Here You can see all your Baked Pizzas!</p>
                <p>Even the ones you sold or sent to a franchise.</p>
              </div>
            }
            bordered={false}
            style={{
              margin: 10,
              borderRadius: 10,
              backgroundColor: pageMode,
              boxShadow: '6px 6px 0px 6px #00bdff',
            }}
            headStyle={{
              color: 'white',
              backgroundColor: '#ff0278',
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              border: 'none',
            }}
          >
            <Row>
              {mintedItems == 'loading' ? (
                <Spin tip="Loading Chef's Creations" indicator={antIcon} />
              ) : (
                mintedItems.map((item) => (
                  <Col>
                    {item.status == 4 &&
                    item.chef.toLowerCase() == walletAddress ? (
                      <div
                        className="wrapper"
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          borderRadius: 5,

                          margin: 10,
                          padding: 5,
                          boxShadow: '0px 0px 40px -9px rgba(0,0,0,0.6)',
                        }}
                      >
                        <Link style={{ color: 'black' }} to="/franchise">
                          Golden Ticket! Go to Franchise
                        </Link>
                      </div>
                    ) : (
                      ''
                    )}
                    {item.chef.toLowerCase() == walletAddress ? (
                      <Card
                        className={
                          item.status == 4 ? 'wrapper' : 'regular-mypizza'
                        }
                        cover={
                          <div>
                            {Object.values(item)
                              .at(5)
                              .split(',')
                              .map((ing) => {
                                return (
                                  <img
                                    className="ingredient-layer-myrecipe"
                                    src={
                                      ingredientsImages[
                                        ingredientsCodesReversed[ing]
                                      ]
                                    }
                                  />
                                );
                              })}
                            <img
                              src={ingredientsImages.basePizza}
                              style={{ width: 240 }}
                            />
                          </div>
                        }
                        hoverable
                        title={
                          <Row>
                            <Col span={12}>
                              {item.name.length > 18 ? (
                                <div className="items-titles">
                                  <p>
                                    {item.name.slice(0, 18).toUpperCase() +
                                      '...'}
                                  </p>

                                  <p>{`#${item.tokenId}`}</p>
                                </div>
                              ) : (
                                <div className="items-titles">
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <p
                                      style={{
                                        padding: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {item.name.toUpperCase()}
                                    </p>
                                    <img
                                      src={
                                        appIcons[
                                          pizzaStatus
                                            .at(item.status)
                                            .toLowerCase()
                                        ]
                                      }
                                      style={{ width: 64 }}
                                    />
                                  </div>
                                  <p
                                    style={{ fontSize: 'small' }}
                                  >{`ID: ${item.tokenId}`}</p>
                                  <p>{pizzaStatus.at(item.status)}</p>
                                </div>
                              )}
                            </Col>
                          </Row>
                        }
                        bordered={false}
                        style={{
                          width: 240,
                          marginLeft: 6,
                          marginRight: 6,
                          marginBottom: 10,
                          borderRadius: 16,
                          boxShadow:
                            pageMode == 'white'
                              ? '0px 0px 20px -9px rgba(0, 0, 0,0.6)'
                              : '0px 0px 20px 0px rgba(255, 2, 120,1)',
                        }}
                        headStyle={{
                          color: item.status == 4 ? 'black' : 'white',
                          backgroundColor: item.status == 4 ? '' : '#ff0278',
                          borderTopRightRadius: 10,
                          borderTopLeftRadius: 10,
                          border: 'none',
                        }}
                      >
                        <Meta
                          description={
                            <div className="item-info">
                              <p className="items-labels">Recipe</p>
                              <Row>
                                {Object.values(item)
                                  .at(5)
                                  .split(',')
                                  .map((ing) => {
                                    return (
                                      <img
                                        src={
                                          ingredientsIcons[
                                            ingredientsCodesReversed[ing]
                                          ]
                                        }
                                        className="small-icons"
                                      />
                                    );
                                  })}
                              </Row>
                            </div>
                          }
                          bordered={true}
                          style={{
                            marginBottom: 10,
                            borderRadius: 16,
                          }}
                        />
                      </Card>
                    ) : (
                      ''
                    )}
                  </Col>
                ))
              )}
            </Row>
          </Card>
        </Col>
        <Col span={12}>
          <Card
            title={
              <div className="owned-title">
                <span>
                  <p>Owned Recipes</p>
                </span>
                <div>
                  <p>
                    {' '}
                    {view == 'Not Listed' ? '🍽 NOT LISTED' : '✔ ON MARKET'}
                  </p>
                  <p>Here You can List your Recipes </p>
                  <p>on the Market!</p>
                </div>

                <span>
                  <button
                    className="show-btn"
                    onClick={(e) => switchView(e)}
                    style={{ border: 'none', borderRadius: 5 }}
                  >
                    {view}
                  </button>
                </span>
              </div>
            }
            bordered={false}
            style={{
              margin: 10,
              borderRadius: 10,
              backgroundColor: pageMode,
              boxShadow: '6px 6px 0px 6px #ff0278',
              background: pageMode == 'white' ? 'white' : 'black',
            }}
            headStyle={{
              color: 'white',
              backgroundColor: '#00bdff',
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
              border: 'none',
              paddingRight: 10,
            }}
          >
            {view == 'For Sale' ? (
              <Row>
                {marketItems == 'loading' ? (
                  <Spin tip="Loading Market" indicator={antIcon} />
                ) : (
                  marketItems.map((item) => (
                    <Col>
                      {item.seller.toLowerCase() == walletAddress &&
                      item.listed == true ? (
                        <Card
                          cover={
                            <div>
                              {Object.values(item)
                                .at(6)
                                .split(',')
                                .map((ing) => {
                                  return (
                                    <img
                                      className="ingredient-layer"
                                      src={
                                        ingredientsImages[
                                          ingredientsCodesReversed[ing]
                                        ]
                                      }
                                    />
                                  );
                                })}
                              <img
                                src={ingredientsImages.basePizza}
                                style={{ width: 240 }}
                              />
                            </div>
                          }
                          title={
                            <Row>
                              <Col span={8}>
                                <div className="items-titles">
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <p
                                      style={{
                                        padding: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {item.name.toUpperCase()}
                                    </p>
                                    <img
                                      src={
                                        appIcons[
                                          pizzaStatus
                                            .at(item.status)
                                            .toLowerCase()
                                        ]
                                      }
                                      style={{ width: 64 }}
                                    />
                                  </div>
                                  <p
                                    style={{ fontSize: 'small' }}
                                  >{`ID: ${item.tokenId}`}</p>
                                </div>
                              </Col>
                            </Row>
                          }
                          bordered={false}
                          style={{
                            width: 240,
                            margin: 6,
                            borderRadius: 16,
                            background: 'none',
                            boxShadow:
                              pageMode == 'white'
                                ? '0px 0px 20px -9px rgba(0, 0, 0,0.6)'
                                : '0px 0px 20px 0px rgb(0, 189, 255)',
                          }}
                          headStyle={{
                            color: item.status == 4 ? 'black' : 'white',
                            background:
                              item.status == 4
                                ? 'linear-gradient(135deg,#844200, #f5af0c 50%,white,#f5af0c,#844200)'
                                : '#00bdff',
                            borderTopRightRadius: 10,
                            borderTopLeftRadius: 10,
                            border: 'none',
                          }}
                        >
                          <Meta
                            description={
                              <div className="item-info">
                                <label className="items-labels">Price</label>
                                <p style={{ color: 'grey' }}>{item.price}</p>
                                <p className="items-labels">Recipe</p>
                                <Row>
                                  {Object.values(item)
                                    .at(6)
                                    .split(',')
                                    .map((ing) => {
                                      return (
                                        <img
                                          src={
                                            ingredientsIcons[
                                              ingredientsCodesReversed[ing]
                                            ]
                                          }
                                          className="small-icons"
                                        />
                                      );
                                    })}
                                </Row>
                              </div>
                            }
                            style={{
                              display: 'flex',
                              marginLeft: 10,
                              marginRight: 10,
                              marginBottom: 10,
                            }}
                          />
                          {item.listed ? (
                            <div className="action-btn">
                              <button
                                className="list-btn"
                                onClick={(e) => delistItem(e, item.itemId)}
                              >
                                Delist From Market
                              </button>
                            </div>
                          ) : (
                            ''
                          )}
                        </Card>
                      ) : (
                        ''
                      )}
                    </Col>
                  ))
                )}
              </Row>
            ) : (
              <Row>
                {mintedItems == 'loading' ? (
                  <Spin tip="Loading Your Recipes" indicator={antIcon} />
                ) : (
                  mintedItems.map((item) => (
                    <Col>
                      {item.owner.toLowerCase() == walletAddress ? (
                        <Card
                          cover={
                            <div>
                              {Object.values(item)
                                .at(5)
                                .split(',')
                                .map((ing) => {
                                  return (
                                    <img
                                      className="ingredient-layer-myrecipe"
                                      src={
                                        ingredientsImages[
                                          ingredientsCodesReversed[ing]
                                        ]
                                      }
                                    />
                                  );
                                })}
                              <img
                                style={{ width: 240 }}
                                src={ingredientsImages.basePizza}
                              />
                            </div>
                          }
                          title={
                            <Row>
                              <Col>
                                <div className="items-titles">
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <p
                                      style={{
                                        padding: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {item.name.toUpperCase()}
                                    </p>
                                    <img
                                      src={
                                        appIcons[
                                          pizzaStatus
                                            .at(item.status)
                                            .toLowerCase()
                                        ]
                                      }
                                      style={{ width: 64 }}
                                    />
                                  </div>
                                  <p
                                    style={{ fontSize: 'small' }}
                                  >{`ID: ${item.tokenId}`}</p>
                                </div>
                              </Col>
                            </Row>
                          }
                          bordered={false}
                          style={{
                            width: 240,
                            margin: 6,
                            borderRadius: 16,
                            background: 'none',
                            color: '#ff0278',
                            boxShadow:
                              pageMode == 'white'
                                ? '0px 0px 20px -9px rgba(0, 0, 0,0.6)'
                                : '0px 0px 20px 0px rgb(0, 189, 255)',
                          }}
                          headStyle={{
                            color: item.status == 4 ? 'black' : 'white',
                            background:
                              item.status == 4
                                ? 'linear-gradient(135deg,#844200, #f5af0c 50%,white,#f5af0c,#844200)'
                                : '#00bdff',
                            borderTopRightRadius: 10,
                            borderTopLeftRadius: 10,
                            border: 'none',
                          }}
                        >
                          {item.listed ? (
                            ''
                          ) : (
                            <div>
                              <div>
                                <label for="price" className="name-txt">
                                  Listing Price:
                                </label>
                                <input
                                  className="name-txt"
                                  id="price"
                                  placeholder="0.0"
                                  type="number"
                                  step="0.01"
                                  min="0.000001"
                                  onChange={(e) => {
                                    setSellingPrice(e.target.value);
                                    setSelectedItem(item.tokenId);
                                  }}
                                />
                                {sellingPrice > 0 &&
                                selectedItem == item.tokenId ? (
                                  <button
                                    className="list-btn"
                                    onClick={(e) => approval(e, item.tokenId)}
                                  >
                                    List on Market
                                  </button>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          )}
                        </Card>
                      ) : (
                        ''
                      )}
                    </Col>
                  ))
                )}
                {boughtItems == 'loading' ? (
                  <Spin tip="Loading Your Owned Recipes" indicator={antIcon} />
                ) : boughtItems == '' ? (
                  ''
                ) : (
                  boughtItems.map((item) => (
                    <Col>
                      {item.owner.toLowerCase() == walletAddress ? (
                        <Card
                          cover={
                            <div>
                              {Object.values(item)
                                .at(3)
                                .split(',')
                                .map((ing) => {
                                  return (
                                    <img
                                      className="ingredient-layer-myrecipe"
                                      src={
                                        ingredientsImages[
                                          ingredientsCodesReversed[ing]
                                        ]
                                      }
                                    />
                                  );
                                })}
                              <img
                                style={{ width: 240 }}
                                src={ingredientsImages.basePizza}
                              />
                            </div>
                          }
                          title={
                            <Row>
                              <Col span={8}>
                                <div className="items-titles">
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <p
                                      style={{
                                        padding: 0,
                                        display: 'flex',
                                        justifyContent: 'center',
                                      }}
                                    >
                                      {item.name.toUpperCase()}
                                    </p>
                                    <img
                                      src={
                                        appIcons[
                                          pizzaStatus
                                            .at(item.status)
                                            .toLowerCase()
                                        ]
                                      }
                                      style={{ width: 64 }}
                                    />
                                  </div>
                                  <p
                                    style={{ fontSize: 'small' }}
                                  >{`ID: ${item.tokenId}`}</p>
                                </div>
                              </Col>
                            </Row>
                          }
                          bordered={false}
                          style={{
                            width: 240,
                            margin: 6,
                            borderRadius: 16,
                            background: 'none',
                            color: '#ff0278',
                            boxShadow:
                              pageMode == 'white'
                                ? '0px 0px 20px -9px rgba(0, 0, 0,0.6)'
                                : '0px 0px 20px 0px rgb(0, 189, 255)',
                          }}
                          headStyle={{
                            color: item.status == 4 ? 'black' : 'white',
                            background:
                              item.status == 4
                                ? 'linear-gradient(135deg,#844200, #f5af0c 50%,white,#f5af0c,#844200)'
                                : '#00bdff',
                            borderTopRightRadius: 10,
                            borderTopLeftRadius: 10,
                            border: 'none',
                          }}
                        >
                          {item.listed ? (
                            ''
                          ) : (
                            <div>
                              <div>
                                <label for="price" className="name-txt">
                                  Price:
                                </label>
                                <input
                                  className="name-txt"
                                  id="price"
                                  placeholder="0.0"
                                  type="number"
                                  step="0.01"
                                  onChange={(e) => {
                                    setSellingPrice(e.target.value);
                                    setSelectedItem(item.tokenId);
                                  }}
                                />
                                {sellingPrice > 0 &&
                                selectedItem == item.tokenId ? (
                                  <button
                                    className="list-btn"
                                    onClick={(e) => approval(e, item.tokenId)}
                                  >
                                    List on Market
                                  </button>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>
                          )}
                        </Card>
                      ) : (
                        ''
                      )}
                    </Col>
                  ))
                )}
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default MyPizzas;
