import { useState, useEffect } from 'react';
import { Card, Row, Col, Button, Modal, Steps, Spin } from 'antd';
import './pizzamarket.css';
import 'antd/dist/antd.css';
import { ethers } from 'ethers';
import Web3Modal from 'web3modal';

import { nftaddress, pizzappmarketaddress } from '../config';

import PizzappMarket from '../_artifacts/PizzappMarket.sol/PizzappMarket.json';
import { LoadingOutlined } from '@ant-design/icons';
import background02 from '../imgs/background02.svg';

const PizzaMarket = ({
  marketItems,
  loadData,
  maxSupply,
  currentWallet,
  pizzaStatus,
  ingredientsImages,
  ingredientsIcons,
  pageMode,
  loadingState,
  ingredientsCodesReversed,
  baked,
  appIcons,
}) => {
  const { Meta } = Card;

  const [listed, setListed] = useState(0);

  const [open, setOpen] = useState(false);
  const [buyingFinished, setBuyingFinished] = useState(false);
  const [modalText, setModalText] = useState('');

  const [currentStep, setCurrentStep] = useState('');

  const { Step } = Steps;
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const showModal = () => {
    setModalText('Buying Recipe, please wait!');
    setOpen(true);
  };
  const handleOk = () => {
    setOpen(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (loadingState == 'Loaded') setListed(marketItems.length);
  }, [loadingState]);

  async function buyNFT(e, nft) {
    e.preventDefault();
    setCurrentStep(0);
    showModal();
    const web3Modal = new Web3Modal();
    const connection = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(connection);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(
      pizzappmarketaddress,
      PizzappMarket.abi,
      signer
    );

    const price = ethers.utils.parseUnits(nft.price.toString(), 'ether');
    try {
      const transaction = await contract.createMarketSale(nft.itemId, {
        value: price,
      }); //nftaddress

      await transaction.wait();
      setModalText('Recipe Successfully Bought!');
      setCurrentStep(1);
      setBuyingFinished(true);
    } catch (error) {
      console.log('Error on Buying NFT', error);
      setModalText('Error on Buying NFT');
      setBuyingFinished(true);
    }

    loadData();
  }

  return (
    <div
      className={pageMode == 'white' ? 'market-stats' : 'market-stats-black'}
      style={{
        backgroundImage: `url(${background02})`,
        backgroundSize: '30%',
      }}
    >
      <Modal
        style={{ fontFamily: 'Sniglet' }}
        title="The Market"
        visible={open}
        closable={false}
        footer={[
          buyingFinished == true ? (
            <div>
              <p>{modalText}</p>{' '}
              <Button type="primary" onClick={handleOk}>
                Close
              </Button>
            </div>
          ) : (
            'Please interact with your wallet when prompted'
          ),
        ]}
      >
        <Steps direction="vertical" current={currentStep}>
          <Step
            title="Buying"
            description={
              currentStep == 0 ? (
                <Spin tip="Buying Recipe, please wait!" indicator={antIcon} />
              ) : (
                ''
              )
            }
          />
          <Step title="Done!" description="Recipe Successfully Bought!" />
        </Steps>
      </Modal>
      <span className="market-txt">
        <p style={{ color: pageMode == 'white' ? 'black' : 'white' }}></p>
      </span>
      <Card
        style={{
          border: 'none',
          borderRadius: 10,
          boxShadow: '6px 6px 0px 6px #ff0278',
          background: pageMode == 'white' ? 'white' : 'black',
        }}
        headStyle={{
          color: 'white',
          backgroundColor: '#00bdff',
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          border: 'none',
        }}
        title={
          <div className="market-title">
            <span className="market-txt">
              <p>{`Listed Pizzas: ${listed}`}</p>
            </span>
            <span className="market-txt">
              {`Baked Pizzas: ${baked}/${new Intl.NumberFormat('en-IN', {
                maximumSignificantDigits: 3,
              }).format(maxSupply)}`}
            </span>
          </div>
        }
      >
        <Meta
          style={{ justifyContent: 'center' }}
          description={<p style={{ color: 'grey' }}>"Welcome to the market!</p>}
        />
      </Card>
      <div className={pageMode == 'white' ? 'market' : 'market-black'}>
        {
          <Row>
            {marketItems == 'loading' ? (
              <Spin tip="Loading Market" indicator={antIcon} />
            ) : (
              marketItems.map((item) => (
                <Col>
                  <Card
                    cover={
                      <div>
                        {Object.values(item)
                          .at(6)
                          .split(',')
                          .map((ing) => {
                            return (
                              <img
                                className="ingredient-layer-market"
                                src={
                                  ingredientsImages[
                                    ingredientsCodesReversed[ing]
                                  ]
                                }
                              />
                            );
                          })}
                        <img
                          src={ingredientsImages.basePizza}
                          style={{ width: 240 }}
                        />
                      </div>
                    }
                    title={
                      <Row>
                        <Col span={8}>
                          {
                            <div className="items-titles">
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  alignItems: 'center',
                                }}
                              >
                                <p
                                  style={{
                                    padding: 0,
                                    display: 'flex',
                                    justifyContent: 'center',
                                  }}
                                >
                                  {item.name.toUpperCase()}
                                </p>
                                <img
                                  src={
                                    appIcons[
                                      pizzaStatus.at(item.status).toLowerCase()
                                    ]
                                  }
                                  style={{ width: 64 }}
                                />
                              </div>
                              <p
                                style={{ fontSize: 'small' }}
                              >{`ID: ${item.tokenId}`}</p>
                              <p>{pizzaStatus.at(item.status)}</p>
                            </div>
                          }
                        </Col>
                      </Row>
                    }
                    bordered={false}
                    style={{
                      width: 240,
                      margin: 6,
                      borderRadius: 10,
                      background: pageMode == 'white' ? 'white' : 'black',
                      boxShadow:
                        pageMode == 'white'
                          ? '0px 0px 20px -9px rgba(0, 0, 0,0.6)'
                          : '0px 0px 20px 0px rgba(255, 2, 120,1)',
                    }}
                    headStyle={{
                      color: 'white',
                      background:
                        item.status == 4
                          ? 'linear-gradient(135deg,#844200, #f5af0c 50%,white,#f5af0c,#844200)'
                          : 'rgb(68, 182, 117)',
                      borderTopRightRadius: 10,
                      borderTopLeftRadius: 10,
                      border: 'none',
                    }}
                  >
                    <Meta
                      description={
                        <div className="item-info">
                          <label className="items-labels">Price</label>
                          <p style={{ color: 'grey' }}>{item.price}</p>
                          <label className="items-labels">Seller</label>
                          <p style={{ color: 'grey' }}>{item.seller}</p>
                          <p className="items-labels">Recipe</p>
                          <Row>
                            {Object.values(item)
                              .at(6)
                              .split(',')
                              .map((ing) => {
                                return (
                                  <img
                                    src={
                                      ingredientsIcons[
                                        ingredientsCodesReversed[ing]
                                      ]
                                    }
                                    className="small-icons"
                                  />
                                );
                              })}
                          </Row>
                        </div>
                      }
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    />
                    {item.seller.toLowerCase() != currentWallet ? (
                      <div style={{ paddingTop: 20 }}>
                        <button
                          className="buy-btn"
                          onClick={(e) => buyNFT(e, item)}
                        >
                          Buy Recipe
                        </button>
                      </div>
                    ) : (
                      ''
                    )}
                  </Card>
                </Col>
              ))
            )}
          </Row>
        }
      </div>
    </div>
  );
};

export default PizzaMarket;
